:root{
  --primary : #2A67A2;
  --orange : #EA6627;
  --orangeOverlay : #EA662790;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@keyframes ticketMoreBtnAnimation {
  0% {
    opacity: 0;
  }
  50%{
    opacity: 0;
    right:-15px;
  }
  100%{
    opacity: 1;
    right:6px;
  }
}
@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif !important;
  font-optical-sizing: auto;
}
.simple-icon{
  font-family: "simple-line-icons" !important;
}
.pre-wrap-txt {
  white-space: pre-wrap !important;
}
body {
  background: #f0f3fe;
}
.bg-theme{
  background: red;
}
.sml-theme-btn {
  font-size: 9px;
  text-transform: capitalize;
  padding: 6px 16px;
}
.text-primary {
  color: #2196F3;
}
.borderRadius-10{
  border-radius: 10px
}
.aid_desc{
  min-height: 50px;
}
.webForm-dropable-holder p {
  margin: 0;
}
.webForm-dropable-holder h3 {
  margin: 0;
}
.sun-editor-editable .se-component>figure,.sun-editor-editable .se-component>figure img{
  max-width:100% !important;
}
.page-loader {
  height: 100vh;
  background: #fff9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt-success {
  color: #00b315 !important;
}
.txt-danger {
  color: red !important;
}
.dot{
  display: inline-block;
  height: 10px;
  line-height: 1;
  width: 10px;
  border-radius: 50px;
}
.input-like-btn{
  text-transform: unset;
  border: 1px solid #ddd;
  font-size: 12px;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;
  font-weight: unset;
  min-height: 35px;
}
.list_box_holder{
  position: relative;
}
.list_box_holder:hover .list_box {
  display: block;
}
.list_box {
  position: absolute;
  background: #fff;
  top: 100%;
  display: none;
  padding-top: 5px;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px #5552;
  max-height: 70vh;
  overflow-y: scroll;
  z-index: 1;
}
.list_box p {
  width: 100%;
  font-size: 12px;
  padding: 5px 18px;
  border-bottom: 1px solid #5551;
  white-space: nowrap;
}
.list_box p:hover {
  background: #f1f1f170;
}
.modalCloseBtn {
  cursor: pointer;
}
.trans-5s {
  transition: 0.5s ease-in-out;
}
.gap15 {
  gap: 15px;
}
.gap5 {
  gap: 5px;
}
.mw-100 {
  min-width: 100px;
}
.mw-150 {
  min-width: 150px;
}
.br-1 {
  border-right: 1px solid #ddd;
}
.bb-1 {
  border-bottom: 1px solid #ddd;
}
.bb-1-dark {
  border-bottom: 1px solid #ccc;
}
.txtCapitalize {
  text-transform: capitalize;
}
.sml-avatar {
  font-size: 12px;
  text-transform: uppercase;
}
.d-none {
  display: none !important;
}
.txt-right {
  text-align: right !important;
}
.d-flex {
  display: flex;
}
.flx-gap-10 {
  gap: 10px;
}
.min-w-150 {
  min-width: 150px;
}
.p-3p {
  padding: 3px;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem;
}
.pointer {
  cursor: pointer !important;
}
.label-like-txt {
  font-size: 11px;
  background: #ddd8;
  padding: 5px 15px;
  border-radius: 4px;
}
.ssp-home{
  min-height: 100vh;
}
.mb-1{
  margin-bottom: 1rem;
}
.pl-10p {
  padding-left: 10px !important;
}
.pb-5p {
  padding-bottom: 5px !important;
}
.pb-10p {
  padding-bottom: 10px !important;
}
.pl-20p {
  padding-left: 20px !important;
}
.pr-0{
  padding-right: 0 !important;
}
.pr-20p {
  padding-right: 20px !important;
}
.pr-15p {
  padding-right: 15px !important;
}
.more-box:hover .more-options{
  animation: ticketMoreBtnAnimation .5s linear;
}
.addAsset-datefix {
  width: auto;
  max-width: 100%;
}
.addAsset-datefix p {
  margin-bottom: 5px;
  display: block;
  margin-top: 0;
  line-height: 1.7;
}
.ticketID-label{
    padding: 10px;
    background: #E3F2FD;
    border-radius: 6px;
    font-size: .7rem;
    line-height: 1;
    white-space: nowrap;
}

.ticketinfo-view{
  background: #fff;
  padding: 10px;
}

.addAsset-datefix > div {
  width: 100%;
}
.flx-25 {
  flex: 0 0 25%;
}
.flx-20 {
  flex: 0 0 20%;
}
.flx-100 {
  flex: 0 0 100%;
}
.flx-50 {
  flex: 0 0 50%;
}
.sml-txt span {
  font-size: 11px;
}
.sml-txt.filterUsers {
  margin: 0;
}
.sml-txt.filterUsers label {
  margin: 0;
}
.sml-txt.filterUsers span {
  padding: 0;
}
.sml-txt.reportRadios > span {
  padding: 0 2px 0px 5px;
}
.sml-txt.reportRadios > span svg {
  width: 0.7em;
  height: 0.7em;
}
.p-1 {
  padding: 1em;
}
.p-10p {
  padding: 10px !important;
}
.p-5 {
  padding: 5em !important;
}
.p-5p {
  padding: 5px !important;
}
.overflow-scroll-x {
  overflow-x: scroll;
  scrollbar-width: none;
}
.overflow-scroll-x::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.p-2 {
  padding: 2em;
}
.stripe {
  background: #f1f1f1;
}
.px-2 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.p-2p {
  padding-left: 2px;
  padding-right: 2px;
}
.px-5p {
  padding-left: 5px;
  padding-right: 5px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.pt-0 {
  padding-top: 0 !important;
}
.py-5p {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333%;
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
}
.ml-0{ 
  margin-left: 0 !important;
}
.mr2 {
  margin-right: 10px !important;
}
.ml-radio {
  margin-left: 0;
}
.mb2 {
  margin-bottom: 20px !important;
}
.pa-1 {
  padding: 1rem;
}
.pa-05 {
  padding: 0.5rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pb-05 {
  padding-bottom: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pt-4 {
  padding-top: 4rem;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.bigTextarea.sml-txt > div > div {
  padding: 8px 0;
}
.bigTextarea.sml-txt textarea {
  font-size: 12px;
}
span.sml-txt {
  font-size: 11px !important;
}
.sml-txt-dropdown > div > div {
  min-height: 20px;
  height: auto;
  font-size: 10px;
}
.sml-txt-dropdown > div > div > div > div {
  padding: 5px 6px;
}
.sml-txt-dropdown > div > div > div > div svg {
  font-size: 12px;
}
.horzi-fields {
  display: flex;
  align-items: center;
  gap: 5px;
}
.horzi-fields > span {
  flex: 0 0 35%;
  max-width: 35%;
}
.App {
  text-align: center;
}
.accordionDtls::before {
  display: none;
}
.accordionDtls {
  margin: 0 !important;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}
.accrodTitle {
  cursor: default !important;
}
.ovrFlw-Y-80 {
  max-height: 80vh;
  overflow-y: scroll;
}
.accrodTitle.Mui-expanded {
  border-bottom: 1px solid #ddd;
  background: #f1f1f1;
}
.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bg-theme {
  background: #f0f3fe;
}
.bg-theme-secondary{
  background: #c5d0f7;
}
table.table td,
table.table th {
  padding: 10px 20px;
  border-collapse: collapse;
  border: 1px solid #555;
}
table.table {
  width: 50%;
  border: 1px solid #555;
  text-align: left;
  border-collapse: collapse;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.features_list {
  width: 100%;
}
.features_list .row {
  margin: 0;
}
.featureRow {
  /* padding: 10px 0; */
  border-bottom: 1px solid #ddd;
}
.featureItem {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  /* text-align: center; */
  padding: 10px 0px;
  /* border-bottom: 1px solid #ddd; */
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.featureItem p {
  margin: 0;
}
.card-row.d-flex {
  display: flex;
  max-width: 100%;
  flex-basis: 100%;
}
.card-row.d-flex > div {
  flex: 0 0 25%;
  max-width: 25%;
}
.no-recs-alert {
  margin: 0;
  padding: 100px 10px;
  text-align: center;
  font-size: 30px;
}
.thumbs > div {
  box-shadow: none;
  padding: 0;
}
.thumbs > div {
  box-shadow: none;
  padding: 0;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  background-color: #e4e7fe;
}
.PriceBox {
  background: #fff;
  padding: 17px 20px;
  height: 100%;
}
.PriceBox .price {
  display: flex;
  align-items: center;
  min-height: 82px;
}
.PriceBox p {
  line-height: 1.2;
  font-size: 14px;
  margin-bottom: 10px;
}
.PriceBox .price .h1 {
  font-size: 40px;
  padding-right: 10px;
}
.h-100 > div {
  height: 100%;
}
.PriceBox button {
  display: block;
  margin: auto;
  border-radius: 50px !important;
  font-size: 13px;
  background: #5B94EA;
  padding: 5px 30px;
  text-transform: capitalize;
  min-width: 113px;
}
.PriceBox button.btn-outline {
  background: #bc59d4;
  border-color: #bc59d4;
  color: #fff;
  box-shadow: 1px 2px 9px 1px #bc59d4;
}
.PriceBox .desc {
  min-height: 70px;
}
.featureItems p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #555;
}
.PriceBox a {
  text-decoration: none;
}
.featureItems p svg {
  width: 0.8em;
  height: 0.8em;
}
.PricingCards {
  padding: 0 10px;
  padding-top: 10px;
}
.PricingCards > div:first-child > div {
  border-top: 10px solid #ea6627;
}
.PricingCards > div:nth-child(2) > div {
  border-top: 10px solid #fe9500;
}
.PricingCards > div:nth-child(3) > div {
  border-top: 10px solid #2a67a2;
}
.PricingCards > div:nth-child(4) > div {
  border-top: 10px solid #bc59d4;
}
.PricingCards > div:nth-child(5) > div {
  border-top: 10px solid #0073ea;
}
.pricePlan {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.childImg {
  display: block;
  margin: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.txt-primary {
  color: #006dcc;
}
.primary-header {
  background: #006dcc;
  padding: 30px;
  text-align: center;
  color: #fff;
}
.primary-header h4 {
  margin: 0 !important;
}
.pricingFeatures p {
  font-size: 11px;
  color: #5559;
}
.pricingFeatures p span{
  color: #000;
}
.chart-popup .form-fields p {
  font-size: 9px;
  margin-top: 5px;
}
.task-item {
  display: block !important;
  /* background: #fff; */
  border-radius: 12px;
  box-shadow: 0px 0 3px 0px #ddd;
  border: none !important;
  padding: 16px 10px !important;
  margin-top: 10px;
}
/* .task-footer  {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
} */
.task-item .badge {
  font-size: .6em;
  border-radius: 5px;
  color: #175CD3;
  background: #B2DDFF50;
  padding: 3px 9px;
}
.task-item .task-header{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.AssetsTcktsList.id {
  color: #175CD3;
  font-weight: 500;
}
.task-actions{
  text-align: center;
}
.task-actions > span {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}
.task-actions  {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-top: 1px solid #ddd8;
  padding-top: 11px;
}
.task-body p {
  font-size: 12px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiMenu-list {
  /* outline: 0; */
  display: flex !important;
  flex-direction: column !important;
}
.prodList {
  flex-direction: row;
}
.prodItem {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 15px;
}
.prodItem > div > label {
  padding: 10px 15px;
  background: #fff;
  min-height: 100px;
  margin: 0;
  width: 100%;
  cursor: pointer;
}
.prodItem > div > label span:last-child {
  padding-left: 15px;
}
.w-100 {
  width: 100%;
}
.h-inputs > p {
  margin-bottom: 5px;
  display: block;
  width: auto;
  max-width: 100%;
}
.h-inputs {
  display: block !important;
  padding: 0 20px;
}
.h-inputs.sm-pd {
  padding: 0 20px;
}
.h-inputs.reportInput input {
  padding: 5px 10px;
  font-size: 12px;
}

.gray-out {
  background-color: #f0f0f0; /* Light gray background */
  color: #808080; /* Dark gray text */
  border-color: #d1d1d1; /* Light gray border */
  pointer-events: none; /* Prevent interactions */
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.SuccessScreen .topSec {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 30px;
  min-height: 100vh;
  padding-bottom: 0;
  text-align: center;
  justify-content: space-around;
}
.SuccessScreen .topSec img {
  height: 150px;
}

.make-ticketBox {
  position: relative;
}
.shrinked-cardCol .cardHeader > div:first-child {
  flex-wrap: wrap;
}
.shrinked-cardCol .cardHeader > div:first-child > div {
  padding-bottom: 5px;
}
.make-ticketBox::before,
.make-ticketBox::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  border-radius: 50%;
}
.make-ticketBox::before {
  content: "";
  position: absolute;
  right: unset;
  left: 0;
  transform: translate(-50%, -50%);
}
.uploadSnippets {
  padding-right: 15px;
  cursor: pointer;
}
.uploadSnippets img {
  margin-right: 5px;
  border-radius: 50%;
}
.SuccessScreen .topSec img.couple {
  height: 300px;
  transform: translateY(4px);
}
.Succ-title {
  font-size: 38px !important;
  color: #277ee0;
}
.text-center {
  text-align: center;
}
.FreeTrialTitle {
  background: #006dcc;
  padding: 30px;
  text-align: center;
  color: #fff;
}
.FreeTrialTitle p {
  font-size: 25px;
  line-height: 1;
}
.customUrlDiv {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.customUrlDiv * {
  margin: 0 !important;
}
.customUrlDiv .solids {
  background: #c5d0f7;
  padding: 15px 16px;
}
.addUrl {
  width: 100%;
  border: 1px solid #c5d0f7;
  border-left: none;
  border-right: none;
}
.addUrl div {
  padding: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 0;
}
.addUrl fieldset {
  border: none;
}
.addUrl input {
  height: 100%;
  border-radius: 0;
  border: none;
}
.login-or-btn span {
  background: #f1f1f1;
  z-index: 1;
  position: relative;
  padding: 8px;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
}
.login-sso-btn{
  display: block;
  text-align: center;
  font-size: 12px;
  width: max-content;
  margin: auto;
  border: 1px solid #ddd;
  padding: 8px 20px;
  border-radius: 50px;
  cursor: pointer;
}
.login-or-btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ddd;
  left: 0;
  top: 50%;
}
.login-or-btn {
  text-align: center;
  position: relative;
  padding: 12px 0;
}
.LoginWrap {
  background-color: #ffffff;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-attachment: fixed;
}
.active .customBtnLink {
  background: #cce5ff;
}
.active {
  color: #0b0b89 !important;
  opacity: 1 !important;
  font-weight: 900 !important;
}

.LoginWrap::before {
  content: "";
  display: block;
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: rgba(93, 84, 240, 0.5); */
  /* background: -webkit-linear-gradient(
    left,
    rgba(0, 168, 255, 0.5),
    rgba(185, 0, 255, 0.5)
  ); */
  background: -o-linear-gradient(
    left,
    rgba(0, 168, 255, 0.5),
    rgba(185, 0, 255, 0.5)
  );
  background: -moz-linear-gradient(
    left,
    rgba(0, 168, 255, 0.5),
    rgba(185, 0, 255, 0.5)
  );
  background: linear-gradient(
    left,
    rgba(0, 168, 255, 0.5),
    rgba(185, 0, 255, 0.5)
  );
}
.LoginWrap .LoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: relative;
}
.w-100 {
  width: 100% !important;
}
.formHolder {
  position: relative;
  width: max-content;
  max-width: 100%;
  background: #fff;
  margin: auto;
  padding: 50px 50px 20px 50px;
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  box-shadow: 10px 10px 49px -33px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 49px -33px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 49px -33px rgba(0, 0, 0, 0.75);
}
.formHolder__logo {
  position: absolute;
  top: 20px;
  right: 25px;
}
.formHolder > div {
  margin-bottom: 10px;
}
.formHolder > div:last-child {
  margin-bottom: 0px;
  text-align: center;
}
.formHolder .LoginBtn {
  background-color: #bd59d4 !important;
  min-width: 150px !important;
  padding: 10px 10px !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  box-shadow: 0 10px 30px 0px rgb(189 89 212 / 50%) !important;
  -moz-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgb(189 89 212 / 50%) !important;
  -o-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5) !important;
  -ms-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5) !important;
}
.formHolder .LoginBtnDisabled {
  background-color: #a09ca1 !important;
  min-width: 150px !important;
  padding: 10px 10px !important;
  border-radius: 50px !important;
  color: #ffffff !important;
  box-shadow: 0 10px 30px 0px rgba(128, 128, 128, 0.5) !important;
}
.LoginLogo {
  position: relative;
}
.LoginLogo img {
  position: absolute;
  top: 40px;
}
.md-d-none{
  display: none !important;
}
@media screen and (max-width: 780px) {
  .md-d-none{
    display: block !important;
  }
  .LoginLogo {
    padding-top: 50px;
  }
  .LoginLogo img {
    position: static;
  }
  .LoginWrap .LoginContainer {
    align-items: center;
    min-height: 100vh;
    justify-content: center;
    gap: 30px;
  }
  .LoginWrap {
    background-position: bottom;
  }
}
.gutter-v {
  padding: 0 20px;
}
.LoginTopMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
}
.LoginTopMenu a {
  color: #fff;
  text-decoration: none;
  padding: 7px 15px;
  display: inline-block;
  border: 2px solid #fff;
  transition: 0.5s ease-in-out;
}
.LoginTopMenu a:hover {
  background: #fff;
  color: #000;
}
.pricingTopBar {
  display: flex;
  align-items: center;
  padding: 30px 10px;
  gap: 15px;
}
.pricingTopBar h4 {
  line-height: 1;
  font-weight: 500;
}
.pricingBanner {
  position: relative;
}
.pricingBanner::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #ea6627;
  height: 38px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
} 
.featureItem.titles button {
  display: block;
  margin: auto;
  border-radius: 50px !important;
  font-size: 13px;
  background: #2F80ED;
  padding: 4px 18px;
  text-transform: capitalize;
  color: #ffffff;
  border: 2px solid #5b94ea;
  cursor: pointer;
  min-width: 110px;
}
.featureItem.titles a {
  text-decoration: none;
}
.featureItem.titles button.btn-outline {
  background: #ffffff00;
  color: #5b94ea;
}
.packagesSummary .collapse-holder {
  background: transparent;
  box-shadow: none;
}
.packagesSummary .collapseBtn {
  width: max-content;
  margin: auto;
}
.packagesSummary .collapseBtn > div {
  flex-grow: unset;
}
.dataLoader {
  display: flex;
  min-height: 70vh;
  align-items: center;
  justify-content: center;
}
svg.suc.cIcon,
.featureItem svg.suc {
  color: #28a745 !important;
}
svg.err.cIcon,
.featureItem svg.err {
  color: #dc3545 !important;
}

.dataLoader.full-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0002;
  z-index: 999999;
}
.alertBox {
  position: fixed;
  top: 20px;
  width: max-content;
  min-width: 300px;
  background-color: #fdeded !important;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 11px 0px #0002;
  border: 1px solid #fdeded;
  z-index: 99999;
}
.upgradeModal {
  position: relative;
}
.upgradeModal .btn {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  min-width: auto;
}
.upgradeModal .btn-nxt {
  right: 0;
  left: unset;
}
.finalUserList {
  border: 1px solid #ddd;
  padding: 15px 15px;
  margin-top: 15px;
}
.finalUserList > p {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.finalUserList > p:first-child {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
.usrGrupsList {
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}
.usrGrupsList::-webkit-scrollbar {
  width: 5px;
}
.usrGrupsList::-webkit-scrollbar-track {
  background: #00000000;
}
.usrGrupsList::-webkit-scrollbar-thumb {
  background: #dddddd50;
  border-radius: 10px;
}
.usrGrupsList::-webkit-scrollbar-thumb:hover {
  background: #dddddd;
}
.text-right {
  text-align: right;
}
@media screen and (max-width: 980px) {
  .usrGrupsList {
    max-height: unset;
    overflow: auto;
  }
  .pricingTopBar {
    padding: 10px 0;
    display: block;
  }
  .pricingTopBar img {
    height: 45px;
    margin-bottom: 10px;
  }
  .pricingTopBar h4 {
    font-size: 20px;
  }
  .pricingTopBar p {
    font-size: 12px;
  }
  .PricingCards .pricePlan {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .feature_list_row {
    flex-wrap: nowrap;
  }
  .feature_list_row > div {
    min-width: 155px;
  }
  .features_list .col-4,
  .features_list .col-8 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .features_list > div {
    flex-wrap: nowrap;
  }
  .features_list .nameTxt {
    min-width: 200px;
    font-size: 12px;
  }
  .features_list .nameTxt h5 {
    font-size: 15px;
  }
  .featureItem.titles button {
    font-size: 11px;
    padding: 2px 8px;
  }
  .featureItem p {
    font-size: 12px;
  }
  .features_list {
    max-width: 100%;
    overflow-x: scroll;
  }
  .sm-0 {
    padding: 0 !important;
  }
  .prodItem {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px;
  }
  .prodItem > div > label span:last-child {
    font-size: 12px;
    padding-left: 5px;
  }
  .customUrlDiv .solids {
    padding: 10px;
  }
  .customUrlDiv .solids p,
  .addUrl input {
    font-size: 9px;
  }
  .prodItem > div > label {
    min-height: 70px;
  }
  .SuccessScreen .topSec img.couple {
    position: absolute;
    top: 40px;
    height: 250px;
    left: 48%;
    border-radius: 50%;
    width: 250px;
    transform: translateX(-50%);
  }
}
.prodsShopMethod > div > div {
  flex-direction: row;
  padding: 10px;
  padding-bottom: 40px;
}
.MuiMenu-list li {
  font-size: 11px !important;
  line-height: 1;
}
.proImg {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
}
main.basic-v-gutter {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}
.sidebarHolder {
  transition: 0.5s ease-in-out;
}
.menuOpened .sidebarHolder > div {
  transform: translateX(0);
}
.txt-ellipsis p,.txt-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (min-width: 980px) {
  .md-none {
    display: none !important;
  }
}
.tabContent p {
  font-size: 12px;
  margin-bottom: 6px;
}
.tabContent p b {
  min-width: 200px;
  display: inline-block;
}
.p-0 {
  padding: 0 !important;
}
.px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.addFields {
  display: flex;
  align-items: center;
}
.addFields svg {
  color: red;
  height: 14px;
}
.assetTab button {
  text-transform: capitalize !important;
}
.md-modal > div > div {
  max-width: 500px;
  min-width: 500px !important;
}
.addFields button {
  padding: 0;
  width: auto;
  min-width: auto;
  margin-left: 10px;
}
.AssetsTcktsList {
  display: flex;
}
.AssetsTcktsList b {
  min-width: auto !important;
}
.tabContent p > span {
  flex: 0 0 25%;
  max-width: 17%;
  padding: 2px 5px;
}
.groupHolders,
.priorityCols,
.myTicketsDropable {
  min-height: 10px;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
}
.groupHolders > * {
  position: absolute;
  top: 0;
  left: 0;
  background: #3f404470;
  width: 100%;
  height: 100%;
  display: block !important;
  overflow: hidden;
  max-width: 100%;
  transition: 1.5s ease-in-out;
}
.groupHolders > * > * {
  display: none;
}
.d-block {
  display: block;
}
.priorityDropable .priorityCols,
.priorityCols.d-block,
.myTicketsDropable.show {
  display: block !important;
  background: #000;
  border: 2px dashed #555;
  opacity: 0.1;
  max-height: unset;
  width: 100%;
}
.ItemDragged .groupHolders {
  display: block;
}
.PriorityTicketItemDragged.ItemDragged .groupHolders {
  display: none;
}
.groupHolders .userItem {
  display: none;
}
.listed-cards p {
  border-bottom: 1px solid #ddd;
  padding: 6px 0;
}
.iconAvatar {
  display: block !important;
  line-height: 40px !important;
  text-align: center;
}
.img-circle {
  border-radius: 50%;
}
.no-footer .cardFooter {
  padding: 0 !important;
}
.multiSelectDropdown > div {
  font-size: 14px;
}
.ellipses-4l {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 43px;
}
.ellipses-2l {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff9;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileOrg {
  position: relative;
  display: block;
  margin: auto;
  width: max-content;
  line-height: 1;
  height: 80px;
}
.profileOrg img {
  object-fit: cover;
}
.profileOrg label {
  position: absolute;
  left: 0;
  top: 0;
  background: #0007;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 11px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.bt-1 {
  border-top: 1px solid #ddd;
}
.bl-1 {
  border-left: 1px solid #ddd;
}
.profileOrg label:hover {
  opacity: 1;
}
.two-lines-el {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.modal-header-title{
  display: flex;
  justify-content: space-between;
}
.modal-header-title > .ticket-details{
  display:flex;
  align-items: center;
}
.modal-header-title > .ticket-details .priority-color{
  margin-right: 5px;
  margin-left: 50px;
}
.ticketinfo-view .ticketview-list .description p{
  padding-right: 10px;
  margin:0px;
}
.webForm-dropable-holder .block .undefined {
  font-size: 16px !important;
}
.webForm-dropable-holder .block input{
  font-size: 15px;
}
.webForm-dropable-holder .block textarea{
  font-size: 15px;
}
.webforms-details .Select-TicketType div{
  font-size: 12px;
}
/* new changes */
.roleBtn {
  background: #c5d0f7;
  padding: 10px;
  max-width: 150px;
  margin-bottom: 10px;
}
.roleBtn p {
  font-size: 14px;
  text-transform: capitalize;
  overflow: hidden;
}
.aside {
  position: sticky;
  top: 90px;
}
.aside.t-0 {
  top: 0;
}
.btn-txt {
  background: #bec6d1;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.btn-txt.selected {
  background: #c5d0f7;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.thumbs .disabled {
  opacity: 0.3;
  /* filter: brightness(0.9); */
  pointer-events: none;
}
.position-relative {
  position: relative;
}
.sla-txt p {
  font-size: 11px !important;
}
.desc-box-holder {
  position: relative;
}
.desc-box {
  /* position: absolute; */
  top: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  box-shadow: 0 0 2px 0px #0005;
  /* height: 0;
  overflow: hidden; */
  transition: 0.5s ease-in-out;
  /* opacity: 0; */
  padding-bottom: 5px !important;
  border-radius: 5px;
}
.desc-box-holder:hover .desc-box {
  height: auto;
  opacity: 1;
}
.desc-box > * {
  min-height: auto !important;
  font-size: 1.3em !important;
}
.contact-popup-avatar {
  width: 200px;
  height: 200px;
  border-radius: 0;
}
.iconAvatar {
  cursor: pointer;
}
.filterCheckboxes span {
  padding: 0 !important;
}
.filterCheckboxes-labels > label {
  margin: 0;
}
.filter-col-name {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
  display: block;
  padding-bottom: 3px;
}
/* .rdrDefinedRangesWrapper{
  display: none !important;
} */
.custom-chart {
  width: 100%;
  padding: 10px 20px;
  background: #fff;
}
.MuiDataGrid-cellContent {
  font-size: 0.7rem;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-size: 0.8rem;
}
.report-style-class iframe {
  min-height: 500px;
  border: none;
}
.sticky-Top-0 {
  position: sticky;
  top: 0;
}
.img-rounded {
  border-radius: 50% !important;
}
.pr-1 {
  padding-right: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.br-1 {
  border-right: 1px solid #ddd;
}
.bl-1 {
  border-left: 1px solid #ddd;
}
.view-popup-input fieldset {
  border: none;
}
.view-popup-input input {
  font-size: 12px;
}
.view-popup-textarea {
  width: 100%;
  height: 55px !important;
  resize: none;
  border: none;
  background: #f1f1f150;
  padding: 5px 15px;
  font-size: 12px;
  outline: none;
}
.view-popup-input input {
  background: #f1f1f150;
}
.view-popup-input fieldset:focus ~ input {
  background: #0000;
}
.view-popup-input input:focus,
.view-popup-textarea:focus {
  background: #0000 !important;
  border: 1px solid #ddd;
}
.view-popup-input > div {
  background: #0000 !important;
}
.accord-profile-img {
  object-fit: cover;
  border-radius: 50%;
}
.view-labels-dd {
  font-size: 11px !important;
  padding: 5px 10px;
  cursor: pointer;
}
.view-labels-dd:hover {
  background-color: #ddd5;
}
.view-popup-input-holder .cmn-inputBox {
  margin: "0 !important";
}
.view-popup-input-holder .cmn-inputBox {
  margin: 0 !important;
  min-width: 400px;
}
.view-popup-input-holder .cmn-inputBox input {
  background: #0000;
}
.css-mnowub-MuiDataGrid-root .MuiDataGrid-cellContent {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.custom-chart .chart {
  max-width: 70%;
  margin: auto;
}
.LandingPageSSP {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1199;
  height: 100vh;
  overflow-y: scroll;
}
.bigTextarea textarea {
  min-height: 100px !important;
}
.collapseHolder {
  background: #f0f3fe !important;
  border: none;
}
.collapseHolder p {
  font-size: 12px;
}
.collapseHeader {
  flex-direction: row;
  background: #e4eaff;
}
.collapseIcon.disabled {
  opacity: 0.1;
  cursor: default;
}
input.otp-box {
  width: 35px !important;
  height: 35px;
  border: 1px solid #ddd;
  margin: 0 5px;
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.loginForm .txt-danger {
  max-width: 250px;
  font-size: 12px;
  margin-top: 10px;
  /* text-align: left; */
}
@media screen and (max-width: 780px) {
  .se-video-container figure, .se-video-container iframe {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
  }
  .p-sm-0{
    padding : 0 !important
  }
  .formHolder {
    max-width: 300px;
    padding: 25px;
  }
  .sm-pl-0 {
    padding-left: 0 !important;
  }
  .sm-pt-1 {
    padding-top: 0 !important;
  }
  .sm-mt-10 {
    margin-top: 10px !important;
  }
  .icon-sidemenu {
    text-decoration: none;
  }
  .icon-sidemenu * {
    color: #000;
  }
  .icon-sidemenu span {
    padding-left: 15px;
  }
}
.groupUser-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: #0000 !important;
  padding: 0 !important;
}
.groupUser-overlay:hover {
  background: #4169e130 !important;
}
.column-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  display: none;
  opacity: 0.2;
  border: 1px dashed #fff;
}
.new-column-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
  display: none;
  opacity: 0.2;
  width: 100% !important;
  max-height: 100% !important;
  border-radius: 10px;
}
.ItemDragged .column-overlay {
  display: block;
}
.newToMeItemDragged .column-overlay,
.newItemDragged .column-overlay {
  display: block;
}
.newItemDragged .new-column-overlay,
.newItemDragged .groupUser-overlay,
.newToMeItemDragged .groupUser-overlay {
  display: block;
}
.column-overlay > * {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}
.groupUser-overlay > * {
  max-width: 100%;
  height: 100%;
  background: #4169e130;
  border: 1px solid #fff;
  animation: ticketMoreBtnAnimation .3s;
}
.groupUser-overlay > * > * {
  opacity: 0;
}
.user-rec {
  overflow: hidden;
}
.min-150 {
  min-width: 15rem;
}

/*workflow*/
.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 100px);
  width: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  padding: 0 20px;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  padding: 4px;
  border: 1px dashed #5555;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}
.dndflow .dndnode p {
  font-size: 0.9em;
}
/* .dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
} */

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
  .mblViewFile .w-100 {
    max-width: 30%;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.flow-holder {
  width: 50%;
  height: calc(100vh - 150px);
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif !important;
}
.react-flow__attribution a {
  display: none !important;
}
.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}
.dndflow .row {
  gap: 10px;
}
.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 20px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

/* .dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
} */

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 100%;
    max-width: 250px;
  }
}
.reactflow-wrapper {
  height: 450px !important;
  background-size: 50px 50px;
  background-color: #f1f1f1;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      #5551 25%,
      #5551 26%,
      transparent 27%,
      transparent 74%,
      #5551 75%,
      #5551 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      #5551 25%,
      #5551 26%,
      transparent 27%,
      transparent 74%,
      #5551 75%,
      #5551 76%,
      transparent 77%,
      transparent
    );
}
.react-flow__handle {
  width: 11px !important;
  height: 11px !important;
  background: #fff !important;
  border: 1px solid #fff !important;
}
.react-flow__handle.danger-dot {
  background: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}
.react-flow__handle.success-dot {
  background: #3dfe3d !important;
  border: 1px solid #3dfe3d !important;
}
.react-flow__handle-bottom {
  bottom: -4px !important;
}
.react-flow__handle-top {
  top: -4px !important;
}
.sub-btns-div .sml-btn {
  padding: 5px !important;
  min-height: 29px;
  font-size: 10px !important;
  line-height: 11px !important;
  width: 75px !important;
  min-width: 75px !important;
  margin: 0 !important;
}
.sub-btns-div .sml-btn svg {
  font-size: 15px;
}
.GroupBox {
  border: 1px solid #5555;
  background: #fff;
  /* #d4dcfe; */
  width: 130px;
  flex: 0 0 130px;
  border-radius: 4px;
  overflow: hidden;
}
.GroupBox .name:hover {
  color: #000;
}
.GroupBox p {
  font-size: 11px;
  color: #555;
  transition: 0.4s ease-in-out;
  padding: 4px 5px;
  line-height: 1;
  border-bottom: 1px solid #5552;
  display: flex;
  justify-content: space-between;
}
.GroupBox p.title {
  background: #ebeffc;
  /* #acbefd; */
}
.GroupBox:nth-child(2) p.title,
.GroupBox:nth-child(7) p.title,
.GroupBox:nth-child(12) p.title {
  background: #ebeffc !important;
  /* background: 
   #f5eab4 !important; */
}
.GroupBox:nth-child(2),
.GroupBox:nth-child(7),
.GroupBox:nth-child(12) {
  background: #fff !important;

  /* background: #fef8e2 !important; */
}
.GroupBox:nth-child(3) p.title,
.GroupBox:nth-child(8) p.title,
.GroupBox:nth-child(13) p.title {
  background: #ebeffc !important;

  /* background: #9ae7f1 !important; */
}
.GroupBox:nth-child(3),
.GroupBox:nth-child(8),
.GroupBox:nth-child(13) {
  background: #fff !important;

  /* background: #d3fafe !important; */
}
.GroupBox:nth-child(4) p.title,
.GroupBox:nth-child(9) p.title,
.GroupBox:nth-child(14) p.title {
  background: #ebeffc !important;

  /* background: #f5c297 !important; */
}
.GroupBox:nth-child(4),
.GroupBox:nth-child(9),
.GroupBox:nth-child(14) {
  background: #fff !important;

  /* background: #ffe3cb !important; */
}
.GroupBox:nth-child(5) p.title,
.GroupBox:nth-child(10) p.title,
.GroupBox:nth-child(11) p.title {
  background: #ebeffc !important;

  /* background: #fde3e2 !important; */
}
.GroupBox:nth-child(5),
.GroupBox:nth-child(10),
.GroupBox:nth-child(11) {
  background: #fff !important;
}
.GroupBox p span.name {
  display: flex;
  cursor: pointer;
}
.GroupBox p svg {
  height: 0.7rem;
  width: 0.7rem;
}
.pos-rel {
  position: relative;
}
.react-flow__node {
  border-radius: 5px;
}
.decisionBox {
  padding: 15px;
  min-width: 100px;
  min-height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 11px;
}
.pvtUi {
  width: 100%;
}
.hide-pivot-container .pvtAxisContainer {
  display: none;
}
.hide-pivot-container .pvtVals {
  display: none;
  visibility: hidden;
}
.hide-pivot-container .pvtRenderers {
  display: none;
  visibility: hidden;
}
.hide-pivot-container .modebar-container {
  display: none;
  visibility: hidden;
}
.hide-pivot-container .svg-container {
  /* transform: scale(0.7); */
  width: 100% !important;
}
.hide-pivot-container button {
  display: none;
  visibility: hidden;
}
.hide-pivot-container .pvtTable {
  width: 100% !important;
}
.hide-pivot-container .pvtOutput {
  width: 100% !important;
}

.common-new-input > div {
  padding: 0px !important;
  font-size: 10px;
  /* border: 1px solid #ddd; */
  outline: none;
}
.common-new-input.with-icon > div >div {
  height: auto;
}
.common-new-input.with-icon > div >div {
  height: auto;
  background: unset;
  border: none !important;
}
.common-new-input.with-icon svg {
  font-size: 17px;
}
.common-new-input.with-icon input {
  padding-left: 0px;
}
.common-new-input.multiline > div {
  padding: 10px !important;
}
.common-new-input > div input {
  padding: 10px 15px;
  font-size: 10px;
}
.common-new-input > div textarea {
  font-size: 12px;
}
.common-new-input > div textarea,
.common-new-input > div input {
  outline: none !important;
}
.common-new-input > div:hover fieldset {
  border: 1px solid #ddd;
}
.sidebar {
  padding-left: 50px;
}
.addassest-inputlists input{
  background: white;
}
.add-assest-contact input{
  background-color: white;
}
.assest-type-lists >div{
  background: white;
}
.ticketview-list span {
  color: #2196F3 !important;
}
@media print {
  .sidebar {
    padding-left: 0px !important;
  }

  .MuiToolbar-root {
    display: none !important;
  }
  .sticky-Top-0 {
    display: none !important;
  }
  .divider {
    display: none !important;
  }
  .sidebarHolder {
    display: none !important;
  }

  .report-inside-title-id {
    display: none !important;
  }
  .report-aside-toolbar {
    display: none !important;
  }
  .report-hide {
    display: none !important;
  }

  .top-nav-bar {
    display: none !important;
  }
  .report-hide-margin {
    margin: 0 !important;
  }
}
.agents-text-area{
  color: black !important;
}
.sml-textbox.sml-datePicker .date-picker-input > div input {
    font-size: 9px;
    padding: 7px 10px;
}
.sml-textbox.sml-datePicker .date-picker-input > div button {
  padding: 0;
  margin: -9px;
}
.sml-textbox.sml-datePicker .date-picker-input > div svg {
    font-size: 16px;
}
.addTaskForm {
  padding: 20px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
.addTaskForm .theme-form-label{
  font-size: .6em !important;
}
.article-viewer-box *{
  max-width: 100%;
}
.table-checkbox {
  padding: 0;
}
.article_table_select{
  font-size: 12px;
}
.article_table_select td ,
.article_table_select th {
  padding: 6px !important;
}
.article_table_select td span ,
.article_table_select th span {
  padding: 0 5px;
}
.article_table_select td ,
.article_table_select th {
  padding: 6px !important;
}
.article_table_select td svg,
.article_table_select th svg{
  font-size: 17px;
}
.article_table_select th:nth-child(2) {
  width: 50px !important;
}
.profileImgBox > div {
  background: #fff;
  display: inline-block;
  border: 5px solid #fff;
  box-shadow: 0 0 3px 1px #ddd;
  border-radius: 5px;
  position: relative;
}
.profileImgBox > div img {
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.profileImgBox > div label svg{
  display: block;
  margin: auto;
}
.profileImgBox > div label {
  position: absolute;
  justify-content: center;
  opacity: 0;
  font-size: 10px;
  background: #0007;
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.profileImgBox > div:hover label {
  opacity: 1;
}
.webform-dropdown > div {
  padding: 8px 15px;
  font-size: 12px;
}
.underconstruction{
  text-align: center;
  justify-content: center;
  padding: 100px 10px;
}
.constructionimg{
  padding-top: 20px !important;
}
.underconstruction img{
  /* width: 450px; */
  height: 300px;
}
.underconstruction button{
  margin-top: 20px;
  margin-bottom: 20px;
}
.webForm-checkboxholder{
  flex-direction: row;
}
.webForm-checkboxholder label span {
  font-size: 11px;
  padding:0;
}
.webForm-checkboxholder label svg {
  margin:0;
}
.webForm-checkboxholder label {
  margin: 0;
  margin-right: 10px;
  gap: 5px;
}
.readonly-checkbox {
  pointer-events: none;
  cursor: not-allowed; 
}
.helper-text-webform {
  margin: 0;
}
.webform-fields-holder {
  margin-bottom: 10px;
}
.sidebarHolder li svg {
  height: 1.3rem;
  width: 1.3rem;
}
.adminTable-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.admin-org-table td, .admin-org-table th {
  font-size: .8rem !important;
  line-height: 1.2;
  white-space: nowrap;
  padding: 10px 5px !important;
}
.admin-org-table th:last-child {
  text-align: center;
}
.adminTable-actions > div > svg {
  height: 0.9rem;
  width: 0.9rem;
  cursor: pointer;
}
.adminTable-actions button svg {
  height: 0.8rem;
  width: 0.8rem;
}
.adminTable-actions button {
  font-size: .6rem;
}
.EditPlanForm {
  max-width: 300px;
  padding: 10px 20px;
}
.EditPlanForm {
  max-width: 350px;
}
.EditPlanForm .theme-form-label {
  display: block;
  line-height: 1.5;
}
.pricing-checkBox span {
  padding: 3px;
  font-size: 12px;
}
.pricing-checkBox svg {
  height: .9rem;
  width: .9rem;
}
.pricing-checkBox {
  margin: 0;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: flex-start;
  padding-bottom: 5px;
}
.Response-sla-items{
  width: 150px;
}
.add-priority p{
  font-size: 14px !important;
}
.add-priority .Response-sla-items{
  font-size: 14px !important;
  width: 195px;
}
.add-priority-color input{
  position: relative;
  left: 152px 
}
.add-priority .default-switch{
  position: relative;
  left: 44px;
}
.category-tickettype >.custom-multiselect-dropdown{
  margin-top: 15px;
}
.ssp-global-search .icon-holder {
  height: auto;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  padding-right: 16px;
  font-size: 28px;
}
.ssp-global-search .common-new-input > div {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 0 6px 0px #0002;
}
.ssp-global-search .icon-holder svg {
  font-size: 23px;
}
.ssp-global-search fieldset {
  border: none;
}
.ssp-global-search {
  width: 750px;
  max-width: 100%;
  margin: auto;
}
.search-result-item {
  background: #fff;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  transform: scale(1);
  box-shadow: 0 0 3px 0px #0000;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.search-result-item:hover {
  transform: scale(1.04);
  box-shadow: 0 0 3px 0px #0002;
}
.search-result-item p{
  font-size: 12px;
}
.search-result-item p.desc{
  color: #0008;
  font-size: 11px;
}
.agent-profile-basicInfo{
  padding-left: 20px;
}
.agent-profile-editicon{
  display: inline-flex !important;
}
.agent-rowMargin{
  background-color: white;
}
.agent-details div{
  padding-top: 8px;
  padding-left: 8px;
}
.agent-details div p{
  font-size: 16px;
}
.filter-list-search {
  padding: 10px;
  border-top: 1px solid #ddd;
}
.filter-list-search input {
  padding: 5px 10px !important;
}
.filter-list-items {
    max-height: 200px;
    overflow-y: scroll;
    padding-top: 10px;
}
.filter-list-items .no-records {
  font-size: 10px;
}
.agents-name:nth-child(1) {
  background: #d4dcfe;
}
.agents-name:nth-child(2) {
  background: #fef8e2;
}
.agents-name:nth-child(3) {
  background: #d3fafe;
}
.agents-name:nth-child(4) {
  background: #d4dcfe;
}
.agents-name:nth-child(5) {
  background: #ffe3cb;
}
.agents-name:nth-child(6) {
  background: #fef8e2;
}
.organization-card-details{
  margin-top: 10px;
}
.top-filter-active-btns label span {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 6px 12px;
  border-radius: 5px;
  color: #2f80ed;
}
.top-filter-active-btns label span:first-child {
  display: none;
}
.top-filter-active-btns label {
  margin: 0;
}
.top-filter-active-btns label.checked span {
  border-color: #1e84ff;
}
.top-filter-active-btns div {
  gap: 10px;
}
.last-col {
  flex: 1 0 0%;
}
.last-col > div{
  justify-content:end;
  gap: 15px;
}
.agent-details .label{
  font-size: 12px;
  color: #5559;
}
.slick-prev:before, .slick-next:before{
  color: #353535 !important;
}

.add-priority-ticket-type{
  margin-top: 0px !important;
}
.ticket-status-display {
  margin-top: 0px !important;
  margin-left: 22px !important;
  margin-bottom: 16px;
}
.ticket-status-display span{
  font-size: 14px !important;
}
.ticket-status-display >.custom-multiselect-input >div{
  width: 292px !important;
  padding-top: 7px;
}
.ticket-status-display >.custom-multiselect-input >div >div{
  border: 0px !important;
}
.list-view-box .popover-box p {
  font-size: 10px;
  line-height: 1.5;
}
.contacts-uploadSnippets img {
  border-radius: 50px;
}
.snippets{
  margin-right: 15px !important;
}
.snippets p{
  padding-left: 5px;
}

.rsolveTicketTitle{
    flex-wrap: nowrap;
    width: 100%;
    max-width: calc(100% - 20px);
}
.rsolveTicketTitle p{
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.LabelFieldBox > p {
  font-size: 13px;
  word-break: break-word;
}
.LabelFieldBox > p:first-child {
  font-size: 11px;
  color: #006dcc;
}
.Ticket-search-item {
  background: #fff;
  padding: 10px;
  box-shadow: 0 0 3px 0px #0002;
  border-radius: 10px;
  cursor: pointer;
}
.ticketToLink {
  transition: .2s ease-in-out;
  border: 2px solid #2f80ed00;
  border-radius: 10px;
}
.ticketToLink.selected {
  border: 2px solid #2f80ed;
}
.Ticket-search-item p {
  font-size: .7em;
}
.Ticket-search-item .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Ticket-search-item .header > div {
  display: flex;
  gap: 6px;
}
.Ticket-search-item p.badges {
  background: #ddd8;
  line-height: 1;
  padding: 4px 8px;
  font-size: 9px;
  border-radius: 50px;
  font-weight: 500;
}
.Ticket-search-item .header p.id {
  font-weight: 600;
  color: #007bff;
  font-size: 0.8em;
}
.spinner-loader {
  display: block;
  height: 15px;
  width: 15px;
  border: 2px solid #2f80ed;
  border-radius: 50px;
  border-left: 2px solid #ddd0;
  border-right: 2px solid #ddd0;
  animation: spinnerAnimation .3s linear infinite;
}
.link-spinner {
  padding-right: 10px;
}
.link-ticket-search > div > p {
  font-size: 11px;
  margin-top: 6px;
  color: #5559;
}
.hide-actions .ticket-moreOptionsBox {
  display: none;
}
.resolve-popup textarea {
  height: 100px !important;
}
.list-view-box .popover-box p {
  max-width: unset;
  word-break: keep-all;
}
.groups-outline{
  margin-top: 0px !important;
}
.groups-heading{
  margin-bottom: 10px !important;
  padding-left: 20px;
}
.groups-databox{
  padding-left: 0px !important;
}
.groups-Group-description{
  padding-top: 0px !important;
  padding-left: 45px !important;
}
.groups-Group-description .description-title{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    padding-bottom: 5px;
}
.Timeline-Note p{
  margin-top: 0px;
}
.mobileMenu-logo img{
  max-width: 40px !important;
}
.tickect-management-button{
  top: 62px !important;
  z-index: 999;
  position: sticky;
  background-color: #f0eff5;
  padding-left: 15px;
  padding: 10px;
}

.datepicker-label{
padding-bottom: 5px;
    display: block;
  }
  
.dark-mode .se-dialog-header {
  color: #fff;
  background-color: #3f4044;
}
.dark-mode  .se-dialog-tabs{
  color: #fff;
  background-color: #1e1c2a;
}
.dark-mode ._se_tab_link .active {
  color: #fff;
  background-color:#1e1c2a;
}
.dark-mode ._se_tab_link{
  color: #fff;
  background-color: #1e1c2a;
}
.dark-mode .tickect-management-button {
  background-color: #1e1c2a;
}
/* .Ticket-management-hr{
  top: 120px;
  z-index: 999;
  position: sticky;
} */

/* .view-service div{
  align-items: end;
} */
.survey-searchbox{
  max-width: 200px;
  margin-top: auto;
  margin-bottom: 23px;
}
.survey-calender {
  margin-top: auto;
  margin-bottom: 30px;
}
.chart-popup .form-fields{
  min-height: 53vh;
}



.termsTable {
  border: 1px solid #ddd;
}

.termsTable th, .termsTable td {
  vertical-align: baseline;
  border: 1px solid #ddd;
}
.confirmationPopup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1399;
  background: #0006;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationPopup .content {
  width: 500px;
  background: #fff;
  max-width: 80%;
  border-radius: 17px;
  position: relative;
}
.confirmationPopup .content .closeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 18px;
  color: #5555;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.confirmationPopup .content p::after {
  content: "";
  display: block;
  height: 1px;
  width: 100px;
  margin: 9px auto 0 auto;
  background: #ddd;
}
.confirmationPopup .content .closeIcon:hover {
  color: #000;
}
.confirmationPopup .content p {
  text-align: center;
  font-size: 1.1rem;
}
.confirmationPopup .content p span {
  display: block;
  font-size: 13px;
  color: #555;
  margin-top: 10px;
}
.colorCheck p {
  font-size: 12px;
  font-weight: 600;
}
.colorCheck p small {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 400;
}
.rbc-event {
  height: auto !important;
}
.rbc-timeslot-group{
  min-height: 75px !important; 
}
.workflow-tasks-summary {
  font-size: 12px;
  font-weight:600;
  margin-top: 10px;
}
.task-item.active {
  background: #d0e8ff;
  border: 1px solid #84bbf0;
}
.taskApproval-buttons button {
  font-size: 12px;
  margin: 0 4px;
  padding: 5px 15px;
}
.top_filter_tasks > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.top_filter_tasks {
  padding: 10px 15px;
}
.task-view-popup .tab-links button {
  font-size: 12px;
  padding: 0;
  line-height: 1;
  height: auto;
  min-height: 36px;
  min-width: 110px;
  text-transform: capitalize;
}
.tab-links {
  min-height: auto;
}
.sml-picker input {
  padding: 8.5px 14px;
  font-size: 12px;
}
.sml-picker .date-picker-input > div > div > button svg {
  height: .7em;
  width: .7em;
}
.mobile-menus button {
  min-width: auto;
  border-radius: 50%;
  color: #fff;
}
.newTickets-link {
  color: #007bff;
}
.allNew-tickets .last-col button {
  min-width: auto;
  margin-right: 10px;
  border-radius: 50%;
  color: #555;
}
.ssp-configuration-defalut{
  border: 1px solid black;
  padding-left: 3px;
  padding-right: 2px;
}
.home-sidebar li.active img {
  box-shadow: 1px 1px 2px 0px #00000030;
}
.home-sidebar li.active span.sidebar-lists {
  opacity: 1;
}
.home-sidebar .sidebar-AI-icon img {
  filter: unset !important;
}
.syncInfo {
  border: 1px solid #5552;
  padding: 30px 15px;
  border-radius: 13px;
  transition: .5s ease-in-out;
  text-align: center;
  box-shadow: 0 0 0px 0px #ddd0;
}
.syncInfo:hover {
  box-shadow: 0 0 10px 2px #ddd;
}
.syncInfo h1 {
  font-size: 3em;
  text-align: center;
}
.syncInfo h3 {
  font-size: 1.3em;
  text-align: center;
}
.syncInfo hr {
  margin: 15px 0;
}
.syncInfo button {
  border: 1px solid #ddd;
  padding: 5px 20px;
  border-radius: 50px;
  text-transform: capitalize;
  color: #000;
}
.syncInfo button:hover {
  background-color: #2F80ED;
  color: #fff;
}
.syncInfo p {
    padding-bottom: 15px;
    font-size: .8em;
    min-height: 100px;
}
.ai-page-title{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.subject_suggestion {
  display: flex;
  /* flex-wrap: wrap; */
  max-width: 65%;
  gap:10px;
  width: 100%;
  overflow-x: scroll;
}
.copilot-input .common-new-input > div,.copilot-input fieldset,.copilot-input input {
  border: none !important;
  outline: none !important;
}
.subject_suggestion p *{
  cursor: pointer;
}
.subject_suggestion p.suggestion-txt {
  font-size: 11px;
  position: relative;
  border: 1px solid #f1f1f160;
  padding: 1px 10px;
  background: #f1f1f160;
  border-radius: 50px;
  margin-top: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 200px;
  justify-content: center;
  min-width: 140px;
  overflow: hidden;
  gap: 5px;
}
.subject_suggestion p.suggestion-txt > span.use-it {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--primary);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  opacity: 0;
  color: #fff;
  font-size: 1.1em;
  font-weight: 700;
  transition: .4s ease-in-out;
  transform: translateY(50%);
}
.subject_suggestion p.suggestion-txt.active > span.use-it {
  transform: translateY(0);
  opacity: 1;
}
.subject_suggestion p.suggestion-txt small {
  padding: 3px 7px;
  background: #c5d0f7;
  border-radius: 10px;
  display: block;
  line-height: 1;
}
.subject_suggestion p.suggestion-txt > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  overflow: hidden;
}
.feature_list_row {
  margin: 0;
  gap: 15px;
  padding: 0 10px;
}
.upgrade_content {
  display: flex;
  min-height: 60vh;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.upgrade_content .icon-holder{
    width: 70px;
    margin: auto;
    height: 70px;
    background: #ffb64230;
    border-radius: 50%;
    padding-top: 13px;
    border : 2px solid #ffb64243
}
.upgrade_content .icon-holder svg path{
  fill : #ffb642;
}
.upgrade_content h3 {
  font-size: 25px;
  margin: 15px 0 10px 0;
}
.upgrade_content p {
  max-width: 700px;
}
.featureItem .featureName > div:first-child {
  width: 100%;
}
.featureItem .featureName {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.type_toggler{
  padding: 30px;
}
.type_toggle_holder {
  display: flex;
  width: 650px;
  position: relative;
  margin: auto;
  border: 1px solid #536587;
  border-radius: 16px;
}
.type_toggle_holder .item {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 22px 30px;
  position: relative;
  gap: 25px;
}
.type_toggle_holder::before {
  width: 50%;
  content: "";
  position: absolute;
  height: 100%;
  background-color: #2a67a210;
  border: 1px solid #2a67a2;
  border-radius: 16px;
  transition: .2s ease-in-out;
  box-shadow: 0px 4px 8px 0px rgba(105.00000000000007, 55.99999999999998, 238.99999999999997, .1411764705882353);
}
.type_toggle_holder.checked::before {
  transform: translateX(100%);
}
.pricingPage .pageTitle p {
  display: flex;
  align-items: center;
  margin: 0 10px;
  gap: 5px;
}
.pricingPage .pageTitle h1 {
  font-size: 3.5rem;
}
.pricingPage .pageTitle p svg {
  color: #2a67a2;
}
.type_toggle_holder .item span{
  color: #2a67a2;
  font-size: 18px;
}
.type_toggler {
  padding: 50px 0;
}
.pricingPage .pageTitle h1 {
  font-size: 3.5rem;
  margin: 35px 0 20px 0;
}
.type_toggle_holder .item img {
  height: 45px;
}
.pricingFeaturesTable p{
  padding : 10px;
  min-height: 51px;
}
.pricingFeaturesTable h4 {
  padding: 10px;
  font-weight: 500;
  font-size: 20px;
}
.pricingFeaturesTable .table_header {
  position: sticky;
  top: 0;
  padding: 11px 0;
  border-bottom: 1px solid #ddd;
  background: #ffffff;
}
.upgrade_btn {
  text-transform: capitalize;
  padding: 5px 17px;
  border-radius: 10px;
  color: #2a67a2;
  border: 1px solid #2a67a2;
  transition: .2s ease-in-out;
}
.upgrade_btn.contact,.upgrade_btn:hover {
  background: #2a67a2;
  color: #fff;
}
.pricingBody {
  background: #FFFFFFE5;
  text-align: center;
  padding: 20px;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.pricingBody .pricing-head h4 {
  font-size: 20px;
}
.pricingBody .pricing-head > div {
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  gap: 10px;
}
.pricingBody .pricing-head > div h2 {
  font-size: 45px;
  font-weight: 700;
}
.pricingBody .pricing-head > div p {
  font-size: 14px;
  line-height: 1.2;
  color: #999;
}
.pricingBody > p {
  font-size: 13px;
}
.roleRow-hint {
  font-size: 10px;
}


.copilot {
  display: flex;
  padding: 15px 20px;
  border-radius: 15px;
}
.copilot .copilot-input {
  width: 100%;
  display: flex;
  align-items: center;
}
.copilot .copilot-input .common-new-input-holder {
  width: 100%;
}
.suggestionItem > p {
  font-size: .8em;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
}

.suggestionItem {
  padding: 10px 12px;
  border-bottom: 1px solid #ddd8;
}
/* .suggestion-popover {
  pointer-events: unset;
} */
.suggestion_content p {
  font-size: 12px;
  min-height: auto;
}
.sla-holder{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.sla-holder .assignee {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-right: 10px;
}
.sla-holder p {
  font-size: 10px !important;
}
.task-footer .theme-form-label {
  font-size: 10px !important;
}
.sla-holder .action {
  min-width: 150px;
  display: flex;
  align-items: flex-end;
  padding: 5px;
  gap: 5px;
}
.sla-holder .action button {
  font-size: 11px;
  text-transform: capitalize;
}
.btnLike {
  border: 1px solid #ddd;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.outcome-popup {
  z-index: 9999;
}
.resolve-popup .Textarea > div {
  height: auto;
}
.resolve-popup input {
  padding: 8px 0 !important;
}
.home-countCard {
  /* background: #f1f1f1; */
  padding: 15px 4px;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
}
.home-countCard p {
  color: #0008;
  font-weight: 500;
  font-size: .7rem;
  margin-top: 5px;
  transition: .3s ease-in-out;
}
.home-countCard:hover p.datalabel {
  transform: translateY(-5px);
}
.home-countCard h4  {
  color: #0008;
  font-size: 1.8rem;
  font-weight: 800;
}
.countTooltip {
  position: absolute;
  top: 5px;
  right: 5px;
}
.countTooltip p {
  margin: 0;
}
.countTooltip-body {
  position: relative;
  top : -7px
}
.countTooltip-body ul li {
  min-width: 200px;
  margin-bottom: 5px;
  transition: .5s ease-in-out;
}
.countTooltip-body ul li.item:hover {
  color: var(--orange);
}
.countTooltip-body ul li.disabled {
  opacity: .5;
  cursor: not-allowed;
}
.countTooltip-body ul {
  margin: 0;
  list-style: none;
  padding: 15px;
  font-size: .8em;
  text-align: left;
}
.countTooltip-body > div {
  position: absolute;
  display: none;
  /* background: #fff; */
  z-index: 1;
  border-radius: 7px;
  box-shadow: 0 0 4px 0px #777;
}
.countTooltip:hover .countTooltip-body > div {
  display: block;
}
.home-chart-box {
  border: 1px solid #ddd;
  border-radius: 9px;
  /* background: #fff; */
  padding: 10px;
  box-shadow: 0 0 2px 0px #ddd;
}
.home-chart-box .home-chart-box {
  border: none;
  box-shadow: unset;
  padding: 0;
}
.chart-group-tiles span {
  font-size: .5em;
  border: 1px solid #ddd;
  display: block;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
}
.chart-group-tiles .otherGroups-list {
  position: absolute;
  display: none;
  min-width: 120px;
  max-height: 165px;
  overflow-y: scroll;
  z-index: 1;
  padding: 0 4px;
  border: 1px solid #ddd;
  right: 0;
  bottom: 102%;
}
.chart-group-tiles .otherGroups:hover .otherGroups-list {
  display: block;
}
.chart-group-tiles .otherGroups {
  position: relative;
}
.chart-group-tiles .otherGroups-list span {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}
.chart-title{
  font-size: 14px;
}
.home-page-controls button {
  padding: 0;
  color: #000;
  cursor: pointer;
  min-width: auto;
  font-size: 21px;
}
.dashboard-grup-selector p {
  padding: 5px 10px;
  font-size: 0.7em;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: .4s ease-in-out;
}

.txt-theme-orange{
  color : var(--orange);
}
.dashboard-grup-selector p:hover {
  background: var(--orangeOverlay);
  color: #fff;
}
.dashboard-grup-selector p.checked{
  background: var(--orange);
  color: #fff;
}
.chart-custom-legends li {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
}
.chart-custom-legends li:hover{
  color : var(--primary);
}
.chart-helper{
  position: relative;
}
.chart-custom-legends {
  flex-wrap: wrap;
  font-size: .6em;
  list-style: none;
  gap: 10px;
  display: none;
  position: absolute;
  bottom: 17px;
  right: -5px;
  width: 150px;
  /* background: #fff; */
  box-shadow: 0 0 1px 0 #000;
  border-radius: 4px;
  padding: 10px;
  margin: 0;
  max-height: 100px;
  overflow-y: scroll;
}
.chart-top-legends .chart-custom-legends {
  display: flex !important;
  position: unset;
  width: auto;
  overflow: unset;
  box-shadow: unset;
}
.chart-custom-legends li .color {
  line-height: 1;
  width: 9px;
  flex: 0 0 9px;
  border-radius: 30px;
  box-shadow: 0 0 1px 0 #000;
}
ul.chart-custom-legends.default-legends {
  display: flex !important;
  position: static;
  overflow: unset;
  border: unset;
  box-shadow: unset;
  padding: 0;
  max-width: unset;
  width: auto;
}
.chart-header {
  display: flex;
  justify-content : space-between;
  align-items: center;
}
.chart-helper:hover ul {
  display: flex;
}
.new-ticket-card-body-row{
  margin-left: -5px;
  margin-right: -5px;
}
.drill-down-menu .head {
  color: var(--orange);
}
.drill-down-menu .sub-head {
  font-size: .7em;
  color: #999;
}
.drill-down-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.drill-down-menu ul li {
  font-size: 12px;
  padding: 5px 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 2px;
  transition: .5s ease-in-out;
}
.drill-down-menu ul li:hover {
  background: #0001;
}
.home-page-controls {
    position: relative;
    z-index: 1;
}
.home-page-controls-popover {
  position: absolute;
  top: 35px;
  right: 0;
  box-shadow: 0 0 2px 0 #0005;
  border-radius: 5px;
}
.home-filter-holder {
    max-height: 300px;
    overflow-y: scroll;
}
.home-cog .count {
  position: absolute;
  top: -9px;
  right: -9px;
  border-radius: 40px;
  background: red;
  color: #fff;
  font-size: .6em;
  min-width: 23px;
}

.home-cog {
  position: relative;
}
.custom-button {
  font-size: .8em !important;
  text-transform: capitalize;
  border: 1px solid;
  padding: 5px 15px !important;
  border-radius: 6px;
  background: var(--orange) !important;
  color: #fff !important;
}
.custom-button.outlined {
  background: none !important;
  color: #000 !important;
  border-color: var(--orange);
}
.home-dashboard-holder{
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}
.SSP-home-Links{
  word-break:break-all;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #333;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  background-color: #e9ecef; 
}
.SSP-home-Links.disabled{
  color: #333;
  cursor: not-allowed;
  background-color: #e9ecef;
  color: #333;
  filter: grayscale(100);
}
.printable-content .se-component.se-video-container figure{
  position: relative;
}
.printable-content .se-component.se-video-container figure iframe{
  position: absolute;
}
/* .se-component figure {
    padding-bottom: 0 !important;
} */
.fields-list-toggler span {
  font-size: .81em;
  padding-right: 10px;
}
.csv-report-table td, .csv-report-table th * {
  font-size: 0.9rem !important;
  max-width: 350px;
}
.csv-report-table th * {
  white-space: nowrap;
}
.csv-report-table tfoot td {
  max-width: unset;
}
.csv-form{
  width: 30%;
}
.csv-top-controls button {
  padding: 0;
}
.csv-form div{
  margin-bottom: 0;
}
.csv-report-table {
  position: relative;
}
.csv-report-table .manage-btn{
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}
.topbar-controls button span {
  color: #270664;
  font-size: 1.3rem;
}
.table-view-row.row-0 .more-box .more-options {
  bottom: unset;
  top: 10px;
}

.contacts-ticket-list p {
  font-size: .7rem;
  border-bottom: 1px solid #ddd6;
  cursor: pointer;
}
.contacts-ticket-list p.active,
.contacts-ticket-list p:hover {
  background: #ddd3;
}
.contacts-ticket-list p {
  font-size: .7rem;
  border-bottom: 1px solid #ddd6;
}
.ticket-info-popup p {
  font-size: 0.8rem;
  word-break: break-all;
}

.ticket-info-popup > div {
  padding: 5px 0;
}

.ticket-info-popup p.label {
  margin-bottom: 5px;
  opacity: .7;
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 800;
}
.modal-header .title-txts {
  margin: 0;
}
.resolve-popup .LabelFieldBox {
  margin-bottom: 10px;
}
.modal-header .rsolveTicketTitle p span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*======= Dark Mode ========= */
.dark-mode .task-item.active {
  background: #020b2f !important;
  color: #fff;
}
.dark-mode .NotificationHeader {
  background: #020b2f !important;
  color: #fff;
}
.dark-mode .css-1n7v3ny-option {
  color: #000 !important;
}
.dark-mode .pvtUi rect.bg {
  fill: #9000 !important;
}
.dark-mode .js-plotly-plot .plotly .main-svg {
  background: unset !important;
}
.dark-mode .pvtUi text {
  fill: #fff !important;
}
.dark-mode .LoginWrap {
  background: #132a3e;
}
.dark-mode .LoginTopMenu a {
  border: none;
}
.dark-mode .modal-header .title-txts > span {
  color: #fff;
}
.dark-mode .topbar-controls button span {
  color: #fff;
}
.dark-mode  .white-bg {
  background: #3f4044 !important;
}
.dark-mode .home-page-controls button {
  color: #fff !important;
}
.dark-mode .home-countCard {
  background: #424242;
  box-shadow: 0 0 5px 0px #0002;
}
.dark-mode .el-inpt-disabled input{
  background-color: #3f4044 !important;
}
.dark-mode .chart-group-tiles span.active {
  color: #fff !important;
  background: #262834;
  border: 1px solid #1e1c2a;
}
.dark-mode .subject_suggestion p.suggestion-txt > span.use-it {
  background: #f1f1f1;
  color: #000;
}
.dark-mode .confirmationPopup .content .closeIcon{
  color : #fff;
}
.dark-mode .allNew-tickets .last-col button{
  color: #fff;
}
.dark-mode .newTickets-link {
  color: #fff;
}
.dark-mode .slick-next:before {
  color: white !important;
}
.dark-mode .slick-prev:before {
  color: white !important;
}
.dark-mode .slick-dots li.slick-active button:before {
  color: white !important;
}
.dark-mode .list-view-box .popover-box p {
  background: #0000 !important;
}
.dark-mode .confirmationPopup .content {
  background: #2e2c2c;
}
.dark-mode .confirmationPopup .content p span {
  color: #fff;
}
.dark-mode .contact-card-header{
  background: #5555;
}
.dark-mode .contact-card {
  background: #5554 !important;
} 
.dark-mode .Ticket-search-item p.badges {
  background: #0005;
}
.dark-mode .confirmationPopup .content .closeIcon:hover {
  color: #fff;
}
body.dark-mode .bg-theme {
  background: #1e1c2a;
}
body.dark-mode .bg-theme-secondary , body.dark-mode  .PriceBox{
  background: #5555;
}
body.dark-mode {
  background: #1e1c2a;
}
.dark-mode .top-filter {
  background: transparent !important;
  border: 1px solid grey !important;
}
.dark-mode .app-loader svg{
  color:white;
}
.dark-mode .agents-text-area {
  color: white !important;
}
.dark-mode .css-7j7ogg > div .MuiBox-root {
  background: transparent;
  border: 1px solid grey;
}
.dark-mode .custom-multiselect-dropdown .react-select-wrapper > div > div {
  background-color: #3f4044;
  color: #ffffff;
}
.dark-mode .card-theme div {
  background: #3f4044 !important;
  color: white !important;
}
.dark-mode .card-months {
  background: #3f4044 !important;
}
.dark-mode .makeStyles-wrapper div {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid grey;
}
.dark-mode .custom-multiselect-input > div > div {
  /* to change */
  background: #3f4044 !important
}
.dark-mode .tickettype-assest div div div div div {
  color: white;
  background: grey !important;
}
.dark-mode .add-assesttype-lists .react-select-wrapper div div div div{
  color: white !important;
}
.dark-mode .edit-report-title{
  color: white !important;
}
.dark-mode .create-report-title button{
  color: white;
}
.dark-mode .edit-report-ChartListItem {
  color:white !important
}
.dark-mode .email-template-title input{
  background-color: #3f4044;
}
.dark-mode .email-template-title fieldset{
  border-color: rgb(206 212 218) !important;
}
.dark-mode .email-template-title #Input-helper-text{
  color: red !important;
}
.dark-mode .emailTemplateMailBox #Input-helper-text{
  color: red !important;
}


.dark-mode .workflow-choose-value div:hover{
  border-color: white;
}
.dark-mode .chart-popup{
  background: #3f4044 !important;
}
.dark-mode .css-26l3qy-menu {
  /* to change */
  background-color: #3f4044 !important;
  color: #ffffff;
}
.dark-mode .rbc-toolbar >.rbc-btn-group >button{
  background-color: #3f4044;
  color: white;
}
.dark-mode p {
  color: white !important;
}
.dark-mode .login-or-btn{
  color: black !important;
}
.dark-mode .login-sso-btn{
  color: black !important;
}
.dark-mode .login-newuser{
  color: black !important;
}
.dark-mode .login-newuser a{
  color: #4f0693 !important;
}
.dark-mode .login-bottom-data{
  color: #555 !important
}
.dark-mode .txt-success {
  color: #00b315 !important;
}
.dark-mode .txt-danger {
  color: red !important;
}
.dark-mode .cardHeader {
  background-color: #505050 !important;
  color: #ffffff;
}
.dark-mode .cardFooter {
  background-color: #505050;
  border-top: #505050 !important;
}
.dark-mode .cardFooter > span {
  color: #ffffff;
}
.dark-mode  .rbc-time-slot{
  border-top: none !important;
}
.dark-mode .cardHolder-card >div >div{
  background: #403f47;
  color: #ffffff;
}
.dark-mode .GroupBox p.title {
  background: #838386 !important;
}
.dark-mode .GroupBox p.title :hover {
  color: #ffffff;
}
.dark-mode .pos-rel {
  background: #4e4f53b3;
  border-bottom: 1px solid rgb(210, 210, 210) !important;
}
.dark-mode .GroupBox.MuiBox-root.css-0 {
  background: #4e4f53b3 !important;
}
.dark-mode .cardContent {
  background: #3f4044;
}
.dark-mode .pos-rel.MuiBox-root.css-0 p :hover {
  color: white !important;
}
.dark-mode .resolved{
  background-color: #3f4044;
}
.dark-mode .pvtUi{
  color: white !important;
}
.dark-mode .pvtTotal{
  background: #555555 !important;
  color:white !important
}
.dark-mode .update {
  background-color: #3e3f42d4;
  color: #fff !important;
}
.dark-mode .make-ticketBox::before,
.dark-mode .make-ticketBox::after {
  background: #838386 !important;
}
.dark-mode .ticket-priority-data {
  color: #ffffff;
}
.dark-mode .cardFooter > div {
  background: #505050;
  color: #fff !important;
}
.dark-mode .modal-content {
  background: #3f4044 !important;
}
.dark-mode .modal-body span {
  color: white !important;
}
.dark-mode .makeStyles-activebtn {
  border: 1px solid white !important;
}
.dark-mode #demo-customized-select {
  background: #3f4044;
 
}
.dark-mode #demo-customized-select em{
 color: grey;
}
.dark-mode #new-tickets {
  background: transparent;
  color: #ffffff;
}

.dark-mode #panel1a-content {
  border-top: 1px solid grey;
  background-color: #3f4044;
}
.dark-mode #panel1a-content > div > div > div {
  background: transparent;
  border-color: gray;
}
.dark-mode #panel1a-content > div > p div {
  border-color: grey;
}
.dark-mode hr {
  border-top: 1px solid rgb(131 131 134) !important;
}
.dark-mode .makeStyles-row-8633 .position-relative {
  border: 1px solid rgb(131 131 134) !important;
}
.dark-mode .makeStyles-row-8633 .makeStyles-newTcktsBox-8980 {
  border: 1px solid rgb(131 131 134) !important;
}
.dark-mode .pt-1 .p-5p {
  /* border: 1px solid rgb(131 131 134) !important; */
}
.categoryPopup-TabLinks.active {
  color: #000 !important;
}

.dark-mode #new-tickets .MuiAccordionSummary-gutters {
  background-color: #3f4044;
}
.dark-mode .new-ticket div div {
  background: #3f4044;
  color: white;
}
.dark-mode .new-ticket div :hover {
  border-color: #ddd;
}
.dark-mode .AddAsset-date .date-picker {
  background-color: #3f4044 !important;
}
.dark-mode .addasset-textarea{
  background: #3f4044;
  color: white;
}
.dark-mode .ticket-dates .date-picker-input >div{
  background-color: #3f4044 !important;
  border-radius: 1px;
}
.dark-mode .speedometer text {
  fill: white !important;
}
.dark-mode .custom-multiselect-input > div > div {
  background: transparent;
}
.dark-mode .custom-mulitselect-dropdowns > div > div {
  background: #3f4044;
}
.dark-mode .custom-mulitselect-dropdowns > div :hover{
  border-color: white !important;
}
.dark-mode .custom-mulitselect-dropdowns > div > div >div >div{
  color: white;
}

.dark-mode .pt-1 > div > div {
  background-color: transparent;
}
.dark-mode .sml-txt-dropdown > div > div > div > div{
  color: white;
}
.dark-mode .contact-heading {
  color: white !important;
}
.dark-mode .tickets-heading{
  color: white !important;
}
.dark-mode .Add-Contacts .addcontactLinkbtn{
  color: white !important;
}
.dark-mode .Add-Attachment{
  color: white !important;
}
.dark-mode .add-asset-icon {
  color: white !important;
}
.dark-mode .sticky-Top-0 > div > div > div {
  color: #ffffff;
  background: #838386 !important;
}
.dark-mode .No-Tickets{
  color: white;
}
.dark-mode .add-contact-user .custom-multiselect-input input{
  background-color: transparent;
}
.dark-mode .rst__rowWrapper .rst__moveHandle {
  background-color: transparent;
}
.dark-mode .rst__rowWrapper .rst__rowContents {
  background-color: transparent;
}
.dark-mode .rst__rowWrapper input {
  background-color: #3f4044;
  border: 1px solid grey;
}
.dark-mode .rst__rowWrapper button {
  background-color: #c5d0f7;
  border: 1px solid grey;
}
.dark-mode .sun-editor {
  background-color: transparent;
}
.dark-mode .sun-editor .se-toolbar {
  background-color: transparent;
}
.dark-mode .sun-editor .se-wrapper .se-wrapper-inner {
  background-color: transparent;
  color: #ffffff;
}
.dark-mode .sun-editor .se-resizing-bar {
  background-color: transparent;
}
.dark-mode .sun-editor button.se-btn.se-tooltip {
  color: #ffffff;
}
.dark-mode .sun-editor .se-list-layer {
  background-color: #3f4044;
  color: #ffffff;
}
.sun-editor .se-btn-list:hover{
  background-color: #b6b6b6 !important;
}
.dark-mode .sun-editor .se-btn-list.default_value {
  background-color: #0000005c;
  border: none;
}
.dark-mode .sun-editor .se-list-inner .se-list-basic li button.active {
  background-color: #3a37377d;
  border: none;
}
.dark-mode .popover-box {
  background: #424242 !important;
  box-shadow: 0 0 4px 0px #1e1c2a !important;
}
.dark-mode .popover-box .contact-type {
  background: #838386 !important;
}
.dark-mode .more-box .more-options {
  background: #3f4044 !important;
  box-shadow: 0 0 4px 1px #1e1c2a !important;
}
.dark-mode .modal-header h5 {
  color: #fff;
}
.dark-mode .load-more-btn span {
  color: #fff;
}
.dark-mode p.toggler:hover {
  color: #fff !important;
}
.groupsHolder {
  height: 150px;
  overflow-y: scroll;
}


.groupsHolder::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
/* .dark-mode .list-view-box td p,
.dark-mode .list-view-box th p {
  background: #555;
} */
.dark-mode .timeline-link {
  background: #555555;
  color: #fff;
}
.dark-mode .priorityToggler.active {
  background: #555 !important;
  color: white !important;
}
.dark-mode .css-1n7v3ny-option {
  color: #000;
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .modal-header h6 {
  color: #fff;
}
.dark-mode .rbc-today,
.dark-mode .rbc-off-range-bg {
  background-color: #555 !important;
}
.dark-mode .rbc-header,
.dark-mode .rbc-date-cell,
.dark-mode .rbc-time-slot,
.dark-mode .rbc-toolbar * {
  color: #fff !important;
}
.dark-mode button.rbc-active {
  background: #555 !important;
}
.dark-mode .rbc-row-segment div {
  background: #555 !important;
  color: #fff;
}
.dark-mode .rbc-events-container .rbc-event {
  background: #555 !important;
  color: #fff !important;
}
.dark-mode tr th {
  background: #555 !important;
}
.dark-mode .category-content li {
  color: #fff;
}
.dark-mode .cmdb-uploadimage{
  color: white;
}
.dark-mode .react-tel-input >input{
  background-color: #3f4044;
  color: white;
  border: 1px solid #CACACA;
}
.dark-mode .react-tel-input >.flag-dropdown >div{
  background-color: #3f4044;
}
.dark-mode .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #3f4044a6;
}
.dark-mode .asset-additional-fields span {
  color: white;
}
.dark-mode .asset-additional-date span {
  color: white;
}
.dark-mode .asset-additional-date .date-picker {
  background:#3f4044;
}
.dark-mode .add-additionalfields span {
  color: white;
}
.dark-mode .add-contact-date .date-picker {
  background: #3f4044;
}
.dark-mode .flag-dropdown.open .country-list{
  background-color: #3f4044;
  color: white;
}
.dark-mode .flag-dropdown.open .search{
  background-color: #3f4044;
}
.dark-mode .flag-dropdown.open .search >.search-box{
  background-color: #3f4044;
  color: white;
}
.dark-mode .react-tel-input .country-list .country.highlight {
  background-color: #918b8bfa;
  color: white;
}
 .dark-mode .react-tel-input .country-list :hover {
  background-color: #555555fa !important;
  color:white;
} 
.dark-mode .react-tel-input .country-list .active {
 color: grey !important;
 background-color: rgb(205, 198, 198);
}
.dark-mode .timeline-heading{
  color:white !important;
}
.dark-mode .timeline-avatar{
  background-color: white;
}
.dark-mode .timeline-update{
  background-color: #3f4044;
  color: white;
}
.dark-mode .timeline-data{
  color: white !important;
}
.dark-mode .timeline-undefined {
  background-color: #89c8ef;
}
.dark-mode .Asset-count{
  background-color: #808080;
  color: white;
}
.dark-mode .Assets-display{
  background-color: #3f4044 !important;
}
.dark-mode .timeline-effort{
  color: white !important;
}
.dark-mode .custom-Report-chart{
  background: #3f4044
}
.dark-mode .pvtRenderers{
  background: #3f4044;
  border: 1px solid grey;
} 
.dark-mode .pvtDropdownValue{
  border-color: grey;
  background: #3f4044 !important;
  color: white;
} 
.dark-mode .pvtTotalLabel{
  background: #555555 !important;
  color: white;
}
.dark-mode .rst__rowTitle input{
  color: white;
}
.dark-mode .pvtGrandTotal{
  background-color: #555555;
  color: white;
}
.dark-mode .pvtAxisContainer{
  background-color: #3f4044 !important;
  border-color: grey;
  position: relative;
}
.dark-mode .pvtDropdownActiveValue{
  background-color: #4c4e50bf;
}
.dark-mode .pvtVals{
  background:#3f4044;
  border-color: grey;
  color: white;
}
.dark-mode .pvtAttr{
  background: #9696963b !important;
    border: 1px solid #9696963b !important;
    color: white;
}
.dark-mode .pvtTriangle{
  color: white;
}
.dark-mode .pvtDropdownIcon{
  color: white;
}
.dark-mode .pvtFilterBox{
  background-color: #3f4044;
  border: 1px solid grey;
}
.dark-mode .pvtDragHandle{
  color: white;
}
.dark-mode .pvtCloseX{
  color:white
}
.dark-mode .pvtSearch{
  background: #3f4044;
  border-color: grey;
  color: white !important;
}
.dark-mode .pvtSearch:focus{
  border-color: grey;
}
.dark-mode .pvtButton{
  color: white !important;
  background: #3f4044;
  border-color: grey;
}
.dark-mode .pvtButton:hover{
  background-color:#9696963b;
  border-color: #9696963b;
}
.dark-mode .pvtCheckContainer{
  border-color: grey;
}
.dark-mode .pvtCheckContainer >.selected{
  background: white;
  color: #000 !important;
}
.dark-mode .pvtCheckContainer >.selected:hover{
  background: white;
  color: #000 !important;
}
.dark-mode .pvtCheckContainer p:hover{
  background-color: #c5e2f5;
  color: black !important;
}
.dark-mode .pvtDropdownMenu{
  border-color: grey;
}
.dark-mode .embeddedPV{
  background: #3f4044;
}
.dark-mode .view-contact-links >a{
  color: blue;
}
.dark-mode .view-contact-links >a:active{
  color: red;
}
.dark-mode .rbc-overlay{
  background-color: #3f4044;
  color: white;
}
.dark-mode .rbc-event{
  background-color: #555 !important;
  color: white !important;
}
.dark-mode .rdrStaticRange{
  background-color: #3f4044;
  color: white;
}
.dark-mode .rdrStaticRange :hover{
  background-color: grey !important;
}
.dark-mode .rdrStaticRangeSelected{
  color: #f0f0f0!important;
  font-weight: 700 !important;
  font-size: 13px;
}
.dark-mode #simple-popover p.active {
  color: white !important;
}
.dark-mode .rdrDefinedRangesWrapper{
  background: #3f4044;
}
.dark-mode .rdrDateDisplayWrapper{
  background-color: #3f4044;
}
.dark-mode .rdrDateDisplayItem{
  background-color: grey !important;
}
.dark-mode .rdrDateDisplayItem input{
  color: white;
}
.dark-mode .rdrDateDisplayItem{
  border-color: white;
}
.dark-mode .rdrMonthName{
  color: white;
}
.dark-mode .rdrDayNumber span{
  color: white;
}
.dark-mode .rdrWeekDay{
  color: white;
}
.dark-mode .rdrMonthAndYearPickers select{
  color: white;
}
.dark-mode .rdrCalendarWrapper{
  background-color: #3f4044;
}
.dark-mode .calender-popup-filter{
  background: #3f4044;
}
.dark-mode #choose-date-range-type{
  color: white !important;
}
.dark-mode .date-range-calender-radio span{
  color: white !important;
}
/* .dark-mode .checked{
  box-shadow: 0 0 0px 4px white !important;
} */
.dark-mode .calender-drag-drop{
  background: #3f4044 !important;
  color: white;
  border-color: grey;
}
.dark-mode .calender-drag-drop .react-select-wrapper div div div div{
  color: white !important;
}
.dark-mode .Alert-popup >div{
  background-color: white !important;
  color:black !important
}
.dark-mode .top-filter-active-btns label.checked span {
  border-color: #dddddd;
}
.dark-mode .top-filter-active-btns label span {
  color: white;
}
.dark-mode .common-btn{
  color: white;
  border-color: white;
}
.dark-mode .common-btn span{
  color: white;
}
.dark-mode .common-btn >img{
  filter: brightness(0) invert(1);
}
.dark-mode .home-nodata{
  color: white !important;
}
.dark-mode .ticket-ms-loading{
  color: white;
}
.dark-mode .outlook-grp >div div{
  border-color: grey;
  color: white;
}
.dark-mode .outlook-grp >div div:hover{
  border-color: white;
}
.dark-mode .gmail-grp >div div{
  border-color: #dddddd;
  border-width: 1.5px !important;
}
.dark-mode .source-heading{
  color: white;
}
.dark-mode .source-options{
  color: white !important;
}
.dark-mode .source-options >div{
 background-color: #3f4044 !important;
}
.dark-mode .custom-checkbox{
  color: white;
}
.dark-mode .source-options-list{
  color: white;
  background-color: #3f4044;
}
.dark-mode .workflow-properties >span{
  color: white;
}
/* .dark-mode .react-flow__controls-button svg{
  filter: brightness(0) invert(1) ;
} */

.dark-mode .workflow-conditions-checkbox >svg{
  color: white !important;
}
.dark-mode .workflow-within-duration{
  background: #5f6063ad;
  color: white;
}
.dark-mode .filters-option{
  background: #505050 !important;
}
.dark-mode .roles-checkbox span {
  color: white;
}
.dark-mode .roles-checkbox{
  color: white;
}
.dark-mode .roles-categories-list{
  background: #3f4044;
}
.dark-mode .accrodTitle{
  background: #3f4044;
}
.dark-mode .stripe{
  background: #3f4044;
}
.dark-mode .filters-option >span{
  background: #5f6063ad;
}
.dark-mode .filter-description{
  color: white;
}
.dark-mode .filter-additionals{
  background: #5a5b5d!important;
}
.dark-mode .ticketinfo-view{
  background-color: #3f4044 !important;
}
.dark-mode .ticketinfo-view-tasks{
  background-color: #1e1c2a !important;
}
.dark-mode .ticketManagementsys-status{
  background-color: grey !important;
}
.dark-mode .rst__lineHalfHorizontalRight::before{
  background-color: grey;
}
.dark-mode .rst__lineHalfHorizontalRight::after{
  background-color: grey;
}
.dark-mode .email-setting-heading{
  color: white;
}
.dark-mode .filter-heading{
  color: white;
}
.dark-mode .worfklow-chart aside {
  background: #5e5d63 !important;
}
.dark-mode .worfklow-chart button {
  color: white;
  background: grey;
}
.dark-mode .dndnode{
  border-color: white;
  background: #7b7a7f !important;
}
.dark-mode .dndflow .aside{
  border-color: white;
  background: #7b7a7f !important;
}
.dark-mode .reactflow-wrapper >div{
  background: #7b7a7f !important;
}
.dark-mode .email-template-bodycontent{
  color: white;
}
.dark-mode .sla-textbox #Input{
  border: 1px solid #ced4da !important;
}
.dark-mode .emailTemplateMailBox button:hover{
  background: grey !important;
}
.dark-mode .worfklow-chart .active {
  color: white !important;
  background: #3f4044 !important;
}
.dark-mode .working-time-plan-card{
  color: white;
}
.dark-mode .add-wtp #Input{
  border: 1px solid #ccc;
}
.dark-mode .timezone-wtp .react-select-wrapper div div div div{
  color: white !important;
}
.dark-mode .hours-days-wtp{
  background: #5e5d66;
}
.dark-mode .working-hours-time{
  background: #3f4044;
  color: white;
  border: none;
  border-radius: 2px;
}
.dark-mode .working-hours-buttn:hover{
  background: grey !important;
}
.dark-mode .wtp-customday-btn{
  filter: brightness(0) invert(1);
}
.dark-mode .whatsapp-group div div div{
  color:white
}
.dark-mode .timeline-total-effort{
  color: white;
}
.dark-mode .timeline-image div img{
  filter: brightness(0) invert(1);
}
.dark-mode .products-values{
  background: #555555;
}
.dark-mode .products-values svg{
  color: white;
}
.dark-mode #account-menu .MuiMenu-list{
  background-color: #555555;
}
.dark-mode #account-menu div:before{
  background-color: grey;
}
.dark-mode .arrow-btns svg{
  color: white;
}
.dark-mode .Agent-groups-image img{
  filter: brightness(0) invert(1);
}
.dark-mode .webforms{
  background: #3f4044 ;
}
.dark-mode .common-new-input > div{
  border: 1px solid #ddd;
  filter: brightness(0) invert(1);
}
.dark-mode .custom-mulitselect-dropdowns > div > div::after{
  border: 1px solid red !important;
}
.dark-mode .common-new-input input{
  padding: 8px;
  color: white;
}
.dark-mode .webforms-subcategory button {
  color: white;
}
.dark-mode .radio-label svg{
  color: white;
}
.dark-mode .dropableWebWorkspace .webform-holder{
  background: #3f4044;
}
.dark-mode .iTtPUa{
  background: #3f4044;
}
.dark-mode .iTtPUa img{
  filter: brightness(0) invert(1);
}
.dark-mode .drag-buttons Button{
  color: white;
}
.dark-mode .list_box_holder:hover .list_box {
  background: #505050;
}
.dark-mode .list_box .radio-label svg{
  color: white !important;
}
.dark-mode .contact-uploadSnippets {
  padding-right: 10px;
} 
.dark-mode .contacts-uploadSnippets{
  padding-right: 3px;
}
.dark-mode .assest-type-lists >div{
  background: #3f4044;
}
.dark-mode .addassest-inputlists input{
  background: #3f4044;
}
.dark-mode .add-assest-contact input{
  background-color:#3f4044;
}
.dark-mode .add-assest-textareas textarea{
  background: #3f4044;
  color: white;
}
.dark-mode .add-assest-uploadimage{
  color:white;
  border-color: white;
}
.dark-mode .addAsset-datefix >div {
  background: #3f4044;
}
.dark-mode .tickect-management-button >button{
  background-color: #838386;
}
.dark-mode .ticketID-label{
  background: #90caf9 !important;
}
.dark-mode .ticket-card-contact b{
  color: white !important;
}
.dark-mode .newticket-Icon{
  color: white;
}
.dark-mode .editticket-quickcontact{
  background-color: #3f4044;
}
.dark-mode .ticket-Timeline{
  color: white !important;
}
.dark-mode .addAsset-AdditionalFields .date-picker-input{
  background-color: #3f4044;
}
.dark-mode .react-select-wrapper .css-1rhbuit-multiValue{
  background-color: grey;
}
.dark-mode .react-select-wrapper .css-1rhbuit-multiValue .css-xb97g8:hover{
  background-color: grey;
}
.dark-mode .react-select-wrapper .css-1rhbuit-multiValue svg:hover{
  color: red;
}
.dark-mode .custom-mulitselect-dropdowns svg {
  color: white;
}
.dark-mode .react-select-wrapper.multi >div >div >div >div >div {
  color: white;
}
.dark-mode .react-select-wrapper.multi  >div >div >div div {
  color: white;
}
.dark-mode .rdrMonthPicker,.rdrYearPicker{
  background: white;
  border-radius: 35px;
}
.dark-mode .rdrMonthPicker select{
  color: black;
  padding: 8px 15px;
}
.dark-mode .rdrYearPicker select{
  color: black;
  padding: 8px 25px;
}
.dark-mode .rdrMonthPicker option {
  background: grey !important;
  color:white !important 
}
.dark-mode .rdrYearPicker option {
  background: grey !important;
  color:white !important 
}
.dark-mode .addNewTaskBtn {
  color: white;
  border-color: white !important;
}
.dark-mode .sml-datePicker{
  background: #3f4044;
  border: 1px solid #cccccc;
    border-radius: 4px 
}
.dark-mode .contact-download > a{
  color: lightblue !important;
}
.dark-mode .add-additional-fields >div{
  background-color: #3f4044;
}
.dark-mode .add-additional-fields >div >div >div{
  color: white !important;
  background-color: transparent !important;
}
.dark-mode .add-additional-fields >div >div div{
  color: #9fa0a2;
}
.dark-mode .add-additional-fields .css-1rhbuit-multiValue{
  background: grey;
}
.dark-mode .add-additional-fields .css-1rhbuit-multiValue >div{
 color: white !important;
}
.dark-mode .add-additional-fields .css-1rhbuit-multiValue >.css-xb97g8:hover{
  color: red !important;
  background: grey !important;
 }
.dark-mode .add-article{
  background-color: #3f4044 !important;
}
 .dark-mode button.se-btn.se-tooltip:hover {
  background: grey!important;
}
.dark-mode .button.se-btn-list:hover {
  background: grey !important;
}
.dark-mode .sun-editor .se-btn:enabled.on {
  background-color: grey !important;
}
.dark-mode .sun-editor .se-list-layer.se-list-format ul * {
  color: white !important;
}
.dark-mode button.se-btn.se-tooltip.active{
  color: lightslategray !important;
}
.dark-mode .article-select-category div div{
  background-color: unset !important;
  border-radius: 4px;
}
.dark-mode .roles-page{
  background: #3f4044;
}
.dark-mode .roles-page >div{
  background: #3f4044;
}
.dark-mode main.basic-v-gutter{
  color: white !important;
}
.dark-mode .accordionDtls{
  background: #3f4044;
}
.dark-mode .accordionDtls button{
  color: white;
}
.dark-mode .modal-footer button {
  color: white;
}
.dark-mode .categoryPopup-TabLinks.active {
  background: #9d9d9d !important;
}

.dark-mode .categoryPopup-TabLinks{
  background-color:#3f4044;
  border:1px solid #bbbbbb;
}
.dark-mode .rst__lineFullVertical::after{
  background-color:grey;
}
.dark-mode .rst__lineChildren::after{
  background-color:grey !important;
}
.dark-mode .sml-txt svg{
  color: white;
} 
.dark-mode .ServicesSSP {
  background: #3f4044;
}
.dark-mode .ssp-services-iconslist svg{
  color: white;
}
.dark-mode .text-center button:hover{
  background-color: #006dcc;
}
.dark-mode .undefined input {
  /* background: #3f4044; */
  border: 1px solid #cccccc;
}
.dark-mode .sun-editor .se-list-inner .se-list-basic li button.active:hover{
  background-color: gray !important;
}
.dark-mode .Wtp-dates > div{
  background-color: #3f4044;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}
.dark-mode .Wtp-dates input{
  color: white;
}
.dark-mode .Wtp-dates svg{
  color: white;
}
.dark-mode .wtp-custom-dates input{
  background-color: #2a2a2b;
  border: 1px solid #2a2a2b;
  border-radius: 4px;
  color: white;
}
.dark-mode .wtp-buttons {
  background-color: #2a2a2b !important; 
}
.dark-mode .wtp-buttons:hover {
  background-color: grey !important; 
}
.dark-mode .MuiCalendarPicker-root{
  background-color: grey;
  color: white;
}
.dark-mode .MuiCalendarPicker-root svg{
  color: white;
}
.dark-mode .MuiCalendarPicker-root span{
  color: white;
}

.dark-mode .se-list-layer {
  background-color: #696969 !important; 
  
}
.dark-mode  .se-selector-table {
  background-color: #696969 !important;
}

.dark-mode .sun-editor .se-selector-table .se-table-size-display{
  color: white;
}
.dark-mode .MuiCalendarPicker-root .Mui-selected{
  background-color: #3f4044 !important;
  color: white;
}
.dark-mode button.se-btn-list{
  color: white;
}
.dark-mode .custom-multiselect-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #3f4044 inset !important;
}
.dark-mode .checkbox-icon{
  color: white !important;
}
.dark-mode .article-viewer-box{
  background-color: #3f4044 !important;
}
.dark-mode .print-article{
  background-color: #3f4044 !important;
}
.dark-mode .articles-lists{
  background-color: #3f4044 !important;
}
.dark-mode .print-article-btn .txtCapitalize{
  color: white !important;
}
.dark-mode .web-form-details{
  background-color: #3f4044 !important;
}
.dark-mode .web-form-details span{
  color: white !important;
}
.dark-mode .article-viewer-box p span{
  color: white !important;
}
.dark-mode .article-viewer-box a{
  color: lightblue;
}
.dark-mode .article-viewer-box a span{
  color: lightblue !important;
}
.dark-mode .Articles-permission{
  background-color: #3f4044 !important;
}
.dark-mode .Article-Type .cardContent {
  background-color: #505050 !important;
}
.dark-mode .Articles-permission .article_table_select >div >.MuiToolbar-regular{
  background-color: #bbbbbb2e !important;
}
.dark-mode .ssp-configuration-defalut{
  border-color: white !important;
}

.dark-mode .CreateSurvey-header .link-content{
  background-color: #3f4044;
}
.dark-mode .CreateSurvey-header .sun-editor-editable a.on {
  color: #4592ff;
  background-color: lightgray;
}
.dark-mode .CreateSurvey-header .sun-editor-editable a {
  color: #4592ff;
}
.dark-mode .CreateSurvey-header .se-dialog-content form{
  background-color: #303133;
  color: white;
}
.dark-mode .CreateSurvey-header .se-dialog-content button{
  color: white;
  background-color: #1e1c2a;
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer>div>label {
  margin: 0 5px 0 0;
  color: black;
}
.dark-mode .CreateSurvey-header .se-dialog-content .se-dialog-body label{
  color: white;
}
.dark-mode .CreateSurvey-header .se-dialog-content .se-dialog-body .se-dialog-form input{
  background-color: transparent;
  color: white;
}
.dark-mode .sun-editor .se-controller-resizing {
 
  background-color: #1e1c2a;
  
}
/* .dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close{
  color: white;
} */
.dark-mode .sun-editor .se-controller .se-btn-group button {
  color: white;
}
.dark-mode .CreateSurvey-header .se-dialog-content .se-dialog-body .se-link-preview{
  color: white;
}
.dark-mode .CreateSurvey-header .se-dialog-content .se-dialog-footer button{
  background-color: #80bdff;
  color: black;
}
.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
  /* border: 1px solid #ccc; */
  color: white;
}
.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-footer{
  background-color: #1e1c2a;
}
/* .dark-mode .sun-editor .se-dialog-tabs button.active{
  background-color: #1e1c2a;
} */
.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
  color: white;
}
.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-body{
  background-color: #1e1c2a;
}

.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form{
  background-color: #1e1c2a;
}
.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button {
  
  color: white;
}

.dark-mode .sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
 
  color: white;
}

/* SSP Design */
.view-ticket{
    background-color: white;
}
.view-ticket-data-outline{
  margin-top: 20px;
  margin-bottom: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 10px;
}
.view-ticket-data{
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.view-ticket-data-outline span{
  color: #007bff;
  display: block;
  font-size: 11px;
  line-height: 1;;
}
.view-ticket-button{
  margin-top: 10px;
  justify-content: center;
}
.view-service {
  background: white;
}
.view-service-outline {
  padding-bottom: 24px;
}
.view-service-outlines{
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 15px;
}
.view-service-data{
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.view-service-outlines span{
  color: #007bff;
  display: block;
  font-size: 11px;
  line-height: 1;;
}
.view-service-button{
  margin-top: 10px;
}
.ssp-user-login-info hr{
  width: 1176px;
}
.ssp-user-login-info .label{
  font-size: 12px;
  color: #5559;
}


/* SSP dark-Mode */
.dark-mode .ssp-home{
  background: #1e1e1e !important;
}
.dark-mode .ssp-headers{
 color: white;
}
.dark-mode .ssp-home-searchbox >div{
  background: #3f4044;
}
.dark-mode .ssp-home-iconHolder{
  background: grey;
  color: white;
  border-bottom: 5px solid #403f47;
}
.dark-mode .ssp-home-iconHolder:hover{
  border-bottom: 5px solid white !important;
}
.dark-mode .ssp-home-iconHolder >img{
  filter: brightness(0) invert(1);
}
.dark-mode .ssp-ticket-status{
  background-color: gray;
}
.dark-mode .ssp-knowledge-icon{
  color: #bb86fc;
}
.dark-mode .view-ticket{
  background: #3f4044;
}
.dark-mode .view-ticket-data-outline{
  border-top: 1px solid grey;
}
.dark-mode .view-ticket-data-outline span{
  color: white;
}
.dark-mode .view-ticket-data{
  border-right: solid 1px grey;
}
.dark-mode .iconAvatar >svg{
  color: #3f4044;
}
.dark-mode .view-service {
  background: #3f4044;
}
.dark-mode .view-service-outlines{
  border-color: grey;
}
.dark-mode .view-service-outlines span{
  color: white;
}

.dark-mode .view-service-data{
  border-color: grey;
}
.dark-mode .viewservice-usericon svg {
  color: white;
}
.dark-mode .viewservice-status{
  color: white;
}
.dark-mode .viewservice-services ul{
  background-color: transparent !important;
}
.dark-mode .myAssets-status{
  color: white;
}
.dark-mode .ssp-create-request-label{
  color: white;
}
main.basic-v-gutter .ssp-create-request div div{
  border: none !important;
}
.dark-mode .ssp-create-request div div{
  border: none !important;
}
.dark-mode .ssp-addnew-attachment div div div {
  background: #3f4044;
  color: white;
}
.dark-mode .home-nodata{
  color: white !important;
}
.dark-mode .ssp-addnew-attachment svg{
  color: white;
}
.dark-mode span.text-primary {
  color: #3edbeb !important;
}
.dark-mode .ticketview-list span{
  color: #90caf9 !important;
}
.dark-mode .ticket-dates > div {
  background: #3f4044 !important;
  border: 1px solid;
  border-radius: 5px;
}
.dark-mode .aid_list_items.active {
  background: white;
  color:black !important
}
.dark-mode .aid_list_items:hover {
  background: grey;
}
.dark-mode .ssp-add-asset-pointer{
  border: 1px solid white;
  padding: 3px;
}
.dark-mode .ssp-asset-status p{
  background: grey;
}
.dark-mode .addAssetModal > div > div:first-child {
  background: #5555;
}
.dark-mode .adminTable-actions button {
  background: #5555;
  color: #fff;
}
.dark-mode .adminTable-actions button:hover {
  background: #555;
}
.dark-mode .agent-rowMargin{
  background-color: transparent !important;
}
.dark-mode .ssp-profile-page{
  background: transparent;
}
.dark-mode .ssp-global-search .common-new-input >div{
  filter: unset;
  background-color:  #3f4044 !important;
}
.dark-mode .ssp-global-search .common-new-input >div:hover {
  border-color: white !important;
}
.dark-mode .search-result-item{
  background-color: #3f4044 !important;
}
.dark-mode .ssp-home-search-icon{
  color: white !important;
}
.dark-mode .ssp-arcticle .white-bg {
  background: #3f4044 !important;
}

.dark-mode .loginForm .formHolder{
  background-color: #383838 !important;
}

.dark-mode .home-page-controls-popover {
  background: #1e1c2a !important;
}



.list-view-box td,
.list-view-box th {
  padding: 13px 4px;
  font-size: 11px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
}
.list-view-box td p,
.list-view-box th p {
  font-size: 11px;
  margin: 0;
  max-width: 230px;
  word-break: break-all;
  white-space: normal;
  line-height: 1.5;
  margin: auto;
}
.list-view-box th {
  /* color:#2196f3; */
  font-size: 12px;
  font-weight: 600;
}
.list-view-box > div {
  padding: 10px;
  padding-bottom: 50px;
}
.colorBox {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 10px;
}
.rightAlignedActions > div > div {
  justify-content: center;
}
.noAccessPage {
  background: #fff;
  padding-bottom: 5rem;
}
.noAccessPage h4 {
  font-weight: 200;
}
.noAccessPage p {
  margin-bottom: 10px;
}
.noAccessPage button {
  text-transform: unset;
}
.white-bg{
  background-color: #fff;
}
.radio-label {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor:pointer
}
.radio-label svg {
  font-size: 18px;
  color: #2196f3
}
.radio-label.disabled {
  opacity: .5;
  pointer-events: none;
  cursor: default;
}
.choose-Category-Btn {
  width: 100%;
  border: 1px dashed #ddd;
  font-size: 12px;
  padding: 19px;
  text-transform: capitalize;
}
#upgradeModalPopup{
  max-width:"100% !important"
}

  


.webformElements {
  text-align: center;
}
.webformElements img {
  width: 40;
  height: 40;
  object-fit: contain;
}
.webformElements p {
  font-size: 0.7rem;
}
.webform-holder{
  min-height: 600px;
}
/* .sortable-chosen.sortable-fallback.sortable-drag {
  height: auto !important;
  width: 500px !important;
} */
.admin_feature_list > div,
.webForm-dropable-holder > div{
  min-height: 100vh;
  border: 1px solid #ddd5;
  padding: 20px;
}
.dark-mode.sidebar.sort-option.active {
  color: white !important;
}
.dropableWebWorkspace.disabled{
  pointer-events: none;
  filter: brightness(.9);
}
.dropableWebWorkspace .dnd-item {
  position: relative;
}
.dropableWebWorkspace .dnd-actions {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
}
.dropableWebWorkspace .dnd-item:hover .dnd-actions {
  display: block;
}
.white_bg{
  padding:15px;
  background: #fff;
}
.aid_list_items{
  font-size: 12px;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}
.aid_list_items:hover,
.aid_list_items.active {
  background: #f1f1f1;
}
.aid_list_items div {
  font-size: 12px;
}
.value_list_item {
  font-size: .8em;
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}
.value_list_item span:first-child {
  display: block;
  min-width: 130px;
  color: #5559;
}
.addNewTaskBtn {
  color: #555;
  border-color: #555 !important;
  border-style: dashed;
  text-transform: capitalize;
  font-size: 10px;
  padding: 6px 25px;
}
.addNewTaskBtn svg{
  font-size: 14px;
}
.dark-mode p.toggler,
.dark-mode p svg:hover {
  color: #fff;
}
.slick-slider{
  width: 100% !important;
}
.slider-img{
  margin-right: 25px !important;
}
.slick-track{
  margin: 0% !important;
}
.childArrow {
  position: relative; 
}
.childArrowImg{
position: absolute;
top: -31px;
/* right:-81px; */
}

.nothing {
visibility: none;
display: none;
}
.linkArrowWrapper{
  position: absolute; 
}
.linkArrowIcon{
  /* position: absolute; */
}
/* .thirdPartyBox-popup .sml-txt-dropdown > div > div > div > div {
  padding: 0;
} */
.resolve-popup .thirdPartyBox-popup .common-new-input input {
  padding: 14.5px 10px !important;
}
.thirdPartyBox-popup .app-field-label {
  font-size: 11px !important;
}
.thirdparty-dropdown > div > div > div {
  padding: 0 8px !important;
}
.resolve-popup .app-field-label {
  font-size: 11px !important;
}

/* mobile-responsive */
@media screen and (max-width: 600px) {
  main.basic-v-gutter{
    padding-right: 7px !important;
    padding-left: 7px !important;
  }
  .home-sidebar{
    width: 80% !important;
  }
  .sidebar-lists{
    color: Black !important;
    font-weight: 700 !important;
  }
  .searchbox{
    width: 180px !important;
  }
  .snippets{
    align-items: baseline;
    padding-bottom: 12px;
  }
  .uploadSnippets{
    padding-bottom: 28px;
  } 
  .contact-uploadSnippets img {
    width: 22px;
    height: 22px;
  }
  .contact-uploadSnippets p{
    font-size: 10px;
    font-weight: 500;
  }
  .contacts-uploadSnippets {
  padding-right: 7px;
  }
  .contacts-first-filter .common-btn{
    font-size: 8px !important;
    font-weight: 600;
  }
  .contacts-uploadSnippets p{
    font-size: 9px;
    font-weight: 500;
  }
  .contacts-uploadSnippets img{
    width: 22px;
    height: 22px;
  }
  .contact-download svg{
    width: 22px;
    height: 22px;
    margin-top: 55px 
  }
  .contacts-first-filter{
    margin-left: 1px !important;
  }
  .common-btn >span{
    margin: 0px !important;
    padding: 6px;
  }
  .assetTab div div >button{
    font-size: 12px;
  }
  .assest-organisation div div{
    margin-bottom: 0px !important;
  }
  .tabContent p{
    display: block;
  }
  .tabContent p > span {
    display: flex;
    font-size: 12px;
  }
  #demo-customized-select{
    font-size: 11px;
  }
  .date-picker input{
    font-size: 12px !important;
  }
  .add-groups-lists input{
    font-size: 12px !important;
  }
  .groups-heading{
    margin: 0px !important;
  }
  .common-top-filter{
    margin-left: 0px !important;
  }
  .usermanagement-common-items{
    margin-top: 4% !important;
  }
  
  .add-assest-textareas textarea{
    border: 1px solid grey;
  }
  .cardlist{
    padding-bottom: 15px !important;
    padding-top: 0px !important;
  }
  .calender-drag-drop{
    padding-top: 0px;
    width: 90%;
  }
  .calender-drag-drop h2{
    font-size: 16px;
    padding-top: 45px;
  }
  .calender-drag-drop > div {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .calender-drag-drop .cardIconBtn{
    margin-left: 90px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .calender-drag-drop div button{
    margin-right: 0px;
    margin-top: 30px;
    font-size: 13px;
    margin-left: 15px;
  }
  .Alert-popup > div div div{
    font-size: 10px;
  }
  
  .assest-contact input{
    font-size: 12px;
  }
  .agents-count{
    flex-wrap: nowrap;
  }
  .add-agents-choosegroup p{
    font-size: 12px !important;
  }
  input{
    font-size: 12px !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    height: 17px !important;
  }
  .makeStyles-inputBox-77396 input {
    padding: 12px !important;
    font-size: 12px;
  }
  .makeStyles-inputBox-52321 input {
    font-size: 12px;
  }
  .add-assest p{
    font-size: 12px;
  }
  .add-assest span{
    font-size: 12px !important;
  }
  .add-assest input {
    font-size: 12px;
  }
  .add-assest h6{
    font-size: 20px;
  }
  .add-assestType h6{
    font-size: 20px;
  }
  .add-assestType p{
    font-size: 12px !important;
  }
  .assest-status-drawer span{
    font-size: 12px !important;
  }
  .add-additionalfields span{
    font-size: 14px !important;
  }
  .permission-cardheader{
    letter-spacing: 0px !important;
    font-size: 8px !important;
    font-weight: 900 !important;
  }
  .category-tickettype {
    display: unset !important;
  }
  .add-category-details{
    padding-top: 5px !important;
  }
  .category-ticket-type{
    display: block !important;
    width: 100% !important;
  }
  .category-tickettype >p{
    padding-bottom: 5px;
    padding-top: 10px;
  }
  .cardSubTitle{
    font-size: 11px !important;
  }
  .cardContent th{
    font-size: 11px !important;
  }
  .cardContent td{
    font-size: 11px !important;
  }
  .timezone-wtp .timezones-wtp{
    display: unset !important;
  }
  .wtp-time-plan-name{
      margin: 0px !important;
  }
  .react-select-wrapper div div{
    font-size: 11px;
  }
  .add-priority p {
    font-size: 14px !important;
  }
  .add-priority .Response-sla-items {
    font-size: 14px !important;
  }
  .add-priority .default-switch{
    position: relative;
    left:-13px !important
  }
  .add-priority-color input{
    position: relative;
    left: 104px !important 
  }
  .category-tickettype >.custom-multiselect-dropdown {
    margin-top: 0px !important;
  }
  .sidebarHolder li svg {
    height: 28px;
    width: 35px;
  }
  .home-sidebar >ul  >img{
    height: 30px;
    width: 30px;
  }
  .category-ticket-type-button{
    height: 35px;
  }
  .add-priority-ticket-type{
    display: unset !important;
  }
  .contact-management-buttons button {
    min-width: 80px !important;
    width: 80px !important;
    height: 28px;
  }
  .contact-management-buttons {
    margin-top: 10px !important;
  }
  .visibleCondition {
    padding-left: 10px !important;
  }
  .formHolder__logo img{
    width: 50px;
    height: 50px;
  }
  .mobileMenu-logo img{
      width: 40px;
  }
  .organization-card-details{
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ticket-type-card{
    padding-bottom: 15px !important;
  }
  .addAssetModal td{
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 0px;
  }
  .addAssetModal th {
    padding: 0% !important;
  }
  .addAssetModal table tbody td >div {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .Dialogcontent-dividers{
    padding: 0px !important;
  }
  .add-priority-color > input{
    block-size: 25px !important;
    padding: 0px !important;
  }
  .priority-cancel-button {
    width: 85px !important;
    padding: 3px !important;
  }
  .Response-sla-details{
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 5px;
  }
  .Response-sla-items{
    width: -1px !important;
  }
  .add-priority .cmn-inputBox >div >div {
    margin-left: 0px !important;
  } 
  .location-type-drawer >div{
    width: 100% !important;
  }
  .two-lines-el{
    padding: 20px !important;
  }
  .assest-drawer >h6{
    font-size: 15px;
  }
  .contact-management-buttons{
    text-align: center;
  }
  .ticket-base-status div {
    width: 108% !important;
  }
 
  .assetTab >div div{
    flex-wrap: wrap;
  }
  .assetTab span{
    background-color: transparent !important;
  }
  .assetTab .Mui-selected{
    background-color: #2196F3;
    color: black;
  }
  .viewasset-img{
    margin-left: 41px !important;
  }
  .CMDB-pageholder{
    padding-right: 0px !important;
    padding-left: 10px !important;
  }
 /* .MuiDialog-scrollPaper{
    height: 50%;
    margin-top: 185px;
    padding: 10px;
  } */
 /* .MuiDialog-scrollPaper >div{
    padding: 10px;
  } */
 .response-sla{
    margin-top: 7px !important;
    align-items: end;
  }
 .wtp-heading h6{
    font-size: 16px;
  }
 .timezone-wtp h1{
    font-size: 10px !important;
  }
 .timezone-wtp .working-hours-time{
   font-size: 10px;
  }
 .wtp-customday-btn {
    width:15px; 
  }
 .create-sla-heading{
   margin-bottom: 0px !important;
  }
 .create-Sla-priority{
   display: unset !important;
  }
 .sla-textbox .cmn-inputBox div{
   margin-bottom: 8px;
  }
 .email-templates h6 {
   font-size: 16px;
  }
  .email-templates span{
    font-size: 12px !important;
  }
 .sun-editor button>svg{
   width: 10px !important;
    height: 10px !important;
  }
 .sun-editor .se-btn-select .txt{
   font-size: 10px !important;
  }
 .sun-editor .se-btn-list{
   font-size: 10px !important;
  }
 .sun-editor .se-wrapper .se-wrapper-inner{
   font-size: 10px;
  }
 .emailTemplateMailBox input {
   font-size: 10px;
  }
 .email-template-roles{
   margin-top: 15px;
  }
 .email-templates-headings {
   margin-bottom: 0px !important;
  }
 .aside input{
   font-size: 12px;
  }
 .add-workflow-datas button {
   font-size: 11px !important;
  }
 .workflow-properties span{
   font-size: 11px !important;
  }
 .workflow-properties input{
   font-size: 11px ;
  }
 .add-workflow-lists div div{
    flex-wrap: wrap;
  }
 .add-workflow-lists span{
   background-color: transparent;
  }
 .add-workflow-lists .Mui-selected {
   background-color: #2196F3;
   color: black;
  }
 .filter-norecords{
   padding: 0px !important;
  }
 .filter-norecords div{
   margin: 0px !important;
  }
 .workflow-within-duration{
   padding-left: 15px;
   padding-right: 15px;
  }
 .workflow-within-duration-dates{
   display: unset !important;
  }
 .source-heading{
   font-size: 17px !important;
    margin-bottom: 15px !important;
  }
 .ticket-source-types{
   flex-wrap: wrap;
  }
 .source-options {
   font-size: 12px !important;
    margin-bottom: 15px;
  }
 .source-options-list div{
   font-size: 13px;
  }
 .source-email {
   padding-bottom: 10px !important;
  }
 .source-options-list{
   padding-bottom: 0px !important;
  }
 .source-icons svg{
   font-size: 15px;
  }
 .add-whattsapp div {
   padding-bottom: 6px;
  }
 .add-whattsapp .react-tel-input{
   padding: 0px;
   margin-bottom: 10px;
  }
 .active-directory-heading{
   margin-top: 20px;
   margin-bottom: 0px !important;
   margin-left: 10px;
  }
  .active-directory-titles p{
    font-size: 13px;
  }
  .active-directory-titles input{
   font-size: 13px;
  }
  .active-directory-subtitles{
    margin-top: 30px;
  }
  .active-directory-subtitles span{
    font-size: 13px;
  }
  .directory-type{
    margin: 0px !important;
  }
  .microsoft-lists input{
    font-size: 13px;
  }
  .email-setting{
    margin-top: 20px;
  }
  .email-setting-heading{
    font-size: 15px !important;
  }
  .emailsetting-outlook span {
    font-size: 13px;
  }
  .outlook-group{
    display: unset;
    margin: 0px;
  }
  .outlook-group-heading {
    margin-top: 15px;
    margin-bottom: 8px;
    padding: 0px !important;
  }
  .outlook-group-heading span{
    font-size: 12px !important;
  }
  .outgoing-mail div{
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .incoming-mail div{
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .gmail-web-mail span{
    font-size: 12px !important;
  }
  .gmail-web-mail input{
    font-size: 12px !important;
  }
  .email-outlook input{
    font-size: 12px !important;
    padding-top: 17.5px !important;
    padding-bottom: 17.5px !important;
  }
  .add-intune span{
    font-size: 12px !important;
  }
  .add-intune input{
    font-size: 12px !important;
  }
  .add-intune-list{
    margin-top: 8px;
    margin-bottom: 13px;
  }
  .add-self-service-heading{
    padding-top: 20px !important;
  }
  .add-self-service span{
    font-size: 12px !important;
  }
  .add-self-service input{
    font-size: 12px !important;
  }
  .add-self-service img{
    width: 30px !important;
    height: 30px !important;
  }
  .add-self-service .cloud-up-load{
    width: 45px !important;
    height: 45px !important;
  }
  .add-self-service .cloud-img{
    width: 30px;
    height: 30px;
  }
  .add-priority h6{
    margin-bottom: 15px !important;
  }
  .priority-ticket-type{
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .add-priority-color{
    margin-top: 20px !important;
  }
  .common-buttn{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .powerBI-lists input{
    font-size: 12px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .add-SSO-Config div div div div{
    margin-bottom: 10px;
  }
  .SSP-Heading{
    margin-bottom: 15px;
  }
  .ServicesSSP .add-service-lists{
    margin-bottom: 10px;
  }
  .new-service{
    margin-bottom: 10px;
  }
  .ssp-services-iconslist{
    padding-top: 10px;
  }
  .ssp-services-iconslist img{
    width: 30px !important;
    height: 30px !important;
  }
  .ssp-services-iconslist .cloud-up-load{
    width: 41px !important;
    height: 41px !important;
  }
  .ssp-services-iconslist .cloud-up-load svg{
    width: 30px !important;
    height: 30px !important;
  }
  .breadcrums{
    padding-bottom: 10px;
  }
  .Agent-groups-groups{
    padding-bottom: 20px;
    border-left: 0px !important;
  }
  .webform-formelements{
    flex-wrap: nowrap;
    padding-right: 1rem;
    padding-top: 1rem;
    gap: 15px;
  }
  .top-nav-bar div >div{
    padding-left: 5px ;
  }
  .webForm-dropable-holder > div{
    min-height: 50vh !important;
  }

  .outlook-group{
    padding: 0% !important;
  }
  .outlook-grp .custom-mulitselect-dropdowns.undefined > div >div >div >div div {
    font-size: 12px ;
  }
  .outlook-group .custom-mulitselect-dropdowns >div{
    width: 152% !important;
  }
  .outlook-group .custom-mulitselect-dropdowns >div >div{
    background: transparent !important;
  }

  .myorganisation-info{
    padding: 0px !important;
  }
  .visibleCondition {
    padding-top: 10px !important;
  }
  .add-ticket-items{
    padding-left: 10px !important;
  }
  .priority-card-items{
    padding-left: 0px !important;
    margin-top: 0px !important;
    
  }
  .organisation-details{
    border-left: 0px !important;
  }
  .dark-mode .home-sidebar{
    background-color: #181722;
  }
  .dark-mode .home-sidebar .sidebarLogo img {
    filter: invert(0);
  }
  .dark-mode .sidebar-lists{
    color: white !important;
    font-weight: 700 !important;
  }
  .dark-mode .sidebarLogo span{
    background: white !important;
  }
  .viewservice-services{
    margin-top: 25px;
  }
  .dark-mode .assetTab .Mui-selected{
    background-color: #555;
    color: white;
  }
  .dark-mode .add-workflow-lists .Mui-selected {
    background-color: #555;
    color: white;
  }
  .profileInfo p{
    font-size: 9px !important;
  }
  .profileInfo h6{
    font-size: 11px;
    margin-bottom: 7px !important;
  }
  .profileInfo svg{
    font-size: 15px;
  }
  .controls{
    padding-left: 20px;
  }
  /* SSP Login Mobile Responsive in Dark-Mode */
  .dark-mode .assest-status-drawer h6 {
    color: white;
  }
  .dark-mode .icon-sidemenu *{
    color: white;
  }
  .dark-mode .bl-1 {
    border-left: none !important;
  }
  .dark-mode .ssp-home >div{
    padding-bottom: 20px;
  }
  .dark-mode .contact-card {
    background: #5554 !important;
}

.dark-mode .top-filter-active-btns label.checked {
    box-shadow: none !important;
}
.dark-mode .top-filter-active-btns label span {
    border-color: #fff0;
    color: #fff
}
.dark-mode .top-filter-active-btns label.checked span {
    border-color: #fff;
}
  .ssp-home-searchbox >div{
    margin-top: 10px;
  }
}

/* .product-items{
  border: 1px solid lightgray;
  margin-bottom: 15px;
}
.Products-list div{
  padding: 5px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  gap: 90px;
}
.product-menu{
  display: block !important;
  text-align: end;
}
.products-outline{
  border-left: 0px !important;
  padding-left: 20px !important;
} */
@media screen and (max-width: 600px){
  .MobileTicket{
    padding-left: 2px;
    max-width: 1000px;
    width: 355px;
  }
  .MobileTicket-Card{
    background:white;
    display:flex;
    padding:2px 8px;
    border-radius:3px;
    margin-bottom: 5px;
  }
  .MobileTicket-options{
    display: flex;
    width: 368px;
    max-width: 1000px;
    position: relative;
    left: -9px;
    top: -15px;
  }
  .MobileTicket-options button{
    background: #fff;
    width: 50%;
    border: 0px;
    border-radius: 0;
    box-shadow: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    padding: 7px;
  }
  .MobileTicket-options button svg{
    color: grey;
  }
  .MobileTicket-categeory{
    position: relative;
    top: -15px;
    left: -9px;
    max-width: 1000px;
    width: 369px;
  }
  .MobileTicket-categeory button{
    border: 0px;
    background: white;
    font-size: 11px;
    padding: 10px;
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid lightgray;
    margin-bottom: 13px;
  }
  .MobileTicket-high svg{
    color: red;
  }
  .MobileTicket-medium svg{
    color: yellow;
  }
  .MobileTicket-low svg{
    color: green;
  }
  .MobileTicket-categeory svg{
    font-size: 11px;
  }
  .MobileTicket-Filter{
    border-left: 1px solid #a4a2a2 !important;
  }
  .Mobileticket-icon svg{
    color: #19279e;
    padding-right: 7px;
    padding-top: 7px;
    font-size: 26px;
  }
  .MobileTickect-details u{
    font-size: 12px;
    color: #007bff;
  }
  .MobileTickect-details p{
    font-size: 15px;
    margin: 0px;
    font-weight: 300;
    margin-bottom: 8px;
  }
  .MobileTickect-End{
    padding-bottom: 3px;
  }
  .MobileTickect-End svg{
    font-size: 21px;
    padding-right: 6px;
    margin-right: 5px;
    margin-top: 4px;
    color: #dc3545;
  }
  .MobileTickect-End .Ticket-avatar{
  font-size: 10px;
  display: block;
  background: #7583b7;
  padding: 0;
  line-height: 24px;
  width: 24px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  margin-right: 5px;
  }
  .MobileTickect-End .todo{
    padding: 2px 8px;
    background: #ddd8;
    line-height: 1;
    border-radius: 11px;
    font-size: 10px;
    margin-right: 7px;
  }
  .MobileTickect-End .action{
    font-size: 10px;
  }
  .MobileTicket-bottom{
    display: flex;
    font-size: 11px;
    text-align: center;
    background: white;
    padding: 10px 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .MobileTickect-addnew svg{
    color: #EA6628 !important;
  }
  .MobileTickect-addnew{
    color: #EA6628;
  }
  .MobileTicket-bottom svg{
    color: #65666c;
    font-size: 25px;
  }
  .MobileTicket-bottom span{
    display: inline-flex;
  }
  .Filters-details{
    display: flex;
    gap: 80px;
  }
  .Filters-details .Mui-selected{
    background: #f1f1f1 ;
    color:#000;
  }
  .Filters-details button{
    color: #000;  
    padding:0px;
    padding-left: 10px;
    background: #fff;
    min-width: 100px;
    font-size: 11px;
    font-weight: 400;
    align-items: baseline;
  }
  .Filters-details span{
   background-color: transparent;
  }
  .Filteritems-values{
    padding: 0px;
    text-transform: capitalize;
  }
  .Filteritems-values div{
    padding: 0px;
    padding-top: 5px 
  }
  .Filteritems-values span{
    padding: 0px;
  }
  .Filteritems-values svg{
    font-size: 15px;
  }
  .Filteritems-status{
    padding-top: 0px;
    text-transform: uppercase;
  }
  .Filter-apply-btn{
    border: none;
    border-radius: 0;
    background: #96b1c7;
    color: #fff;
    width: 100px;
    padding: 10px;
    font-size: 15px;
  }
  .Filter-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    border-top: 1px solid #ddd;
    text-align: end;
  }
  .Sort-details ul{
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 12px;
  }
  .Sort-details li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd5;
    padding: 10px 10px;
  }
  .Sort-details li>i{
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid #555;
      border-radius: 50%
  }
  .Sort-details li>i:active{
    background: #03a9f4;
    border-color: #03a9f4;
  }
  .mobileTicket-add-btn svg{
    height: 50px;
    width: 50px;
    color: #374473;
    position: fixed;
    bottom: 100px;
    right: 15px;
  }
  .MobileTicket-info{
    padding-top: 10px;
  }
  .MobileTicket-info span{
    font-size: 13px !important;
    text-transform: capitalize;
    margin-top: 20px;
    color: #5559;
  }
  .MobileTicket-info h4{
    margin-top: 0px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
  }
  .MobileTicket-info p{
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
    font-weight: 500;
    font-size: 13px;
  }
  .MobileTicket-Card-body{
    padding-bottom: 10px;
  }
  /* .MobileTicket-info-Status{
    display: flex;
    gap: 100px;
  } */
  .MobileTicket-info u{
    color: #007bff;
    font-size: 13px;
  }
  .MobileTicket-info-Status p{
    background-color: #efefef;
    border-radius: 30px;
    padding: 6px;
    text-align: center;
    width: 100px;
  }
  /* .MobileTicket-info-Status p{
    background-color: lightgray;
    border-radius: 30px;
    padding: 6px;
    text-align: center;
  } 
   .MobileTicket-info-priority p{
    background-color: #28a745;
  } */
  .MobileTicketInfo-bottom{
    display: flex;
    justify-content: space-around;
    background: #e9e9e9;
    width: 370px;
    position: relative;
    left: -10px;
    bottom: -9px;
  } 
}
/* .pvtVals > .pvtDropdownMenu > .pvtDropdownValue:nth-child(4)  {
  display: none;
}

.pvtVals > .pvtDropdownMenu > .pvtDropdownValue:nth-child(4)  {
  display: block;
} */
.chatbox {
  padding-top: 10px;
  background-color: #95AAFB;
  color: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.chatbox p{
  font-size: 12px;
}
.chatbox p, .chatbox h5{
  margin: 0px;
}
.chat-box p{
  font-size: 10px;
  margin-top: 20px
}
.chatOptions{
  /* display: "flex";
  gap:20;
  flex-direction: column; */
}
.chat-box p{
  font-size: 12px;
}
.chatOptions a {
  background:#f6f7f7;
  font-size: 12px;
  text-decoration: none;
  color: #3d647e;
  padding: 10px;
  border-radius: 10px;
  border:1px solid #d2d2d2;
  display: flex;
  align-items: center;
}
.chatOptions svg{
  font-size: 15px;
  margin-right: 10px;
}
.chatOptions img{
  margin-right: 10px;
  height: 20px
}
.chat-box-teaxtarea {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: white;
}
.hover-notification-item:hover{
  background-color: #f1f4fe !important;
}
.chat-box-teaxtarea input {
  width: 100%;
  padding: 5px 10px;
  border: none;
  outline: none;
}
.chat-box-teaxtarea svg{
  padding-right: 10px;
  font-size: 36px;
}
/* Email Exception */
.emailFields{
  margin-left: 10px !important;
}
.emailFields div{
  padding-bottom: 3px;
  padding-top: 5px;
}
.emailFields-button{
  width: 100px;
  margin-bottom: 20px ;
}
.mailexception-outgoingmail .undefined{
  flex-wrap: wrap;
}
.mailexception-outgoingmail .add-additional-fields{
  border: 2px solid #ddd !important;
  border-radius: 5px;
}
.mailexception-outgoingmail .add-additional-fields >div{
  border-bottom: 0px ;
  min-height: 48px !important;
}
.mailexception-incomingmail > input{
  background-color: transparent !important  ;
}
.custom-requestUrl-cell{
  color: blue;
}
.custom-exception-cell{
  color: red;
}
.error-log-naviagte{
  text-align: center;;
}
.rbc-overlay {
  max-height: 300px !important; 
  overflow-y: auto !important;  
}
.calender .rbc-toolbar{
  top: 120.5px;
  z-index: 999;
  position: sticky;
  background-color: #f0f3fe;
}
.dark-mode .calender .rbc-toolbar{
  background-color: #1e1c2a
}
.breadcrums{
  position: sticky;
  z-index: 999;
  top: 73px;
  width: 100%;
  background: #f0f3fe;
  margin-bottom: 15px;
}
.dark-mode .breadcrums{
  background-color: #1e1c2a;
}
.dark-mode .WallBoard-Link{
  color: #8ab4f8;
}
.dark-mode .breadcrums{
  background-color: #1e1c2a;
}
.common-top-filter{
  top: 111px !important;
  z-index: 999;
  position: sticky;
  padding: 0px !important;
}
.Article-Type{
  padding-top: 0px !important;
}
.Ranking .breadcrums{
  position: fixed;
  top: 84px;
}
.CSV-Reports .tickect-management-button{
  top: 111px !important;
  z-index: 999;
  position: sticky;
}

.small-button .MuiInputBase-root{
  height: 40px !important;
}
.container-fill-screen {
  height: 100vh; /* Default to full viewport height */
}

@media (max-width: 768px) {
  .container-fill-screen {
    height: calc(100vh - 50px); /* Adjust for mobile headers or footers */
  }
}

@media (max-width: 480px) {
  .container-fill-screen {
    height: calc(100vh - 100px); /* Further adjustment for small devices */
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar only when this class is applied */
}

.pr-10 {
  padding-right: 10px;
}

/* .ticketCardParent {
  width: calc(calc(100vw / 4) - 25px);
}

@media (min-width: 1550px) {
  .ticketCardParent {
    width: calc(calc(100vw / 7) - 13px);
  }
} */

.makeHeaderSticky {
  position: sticky;
  top: 0;
  background: white; /* Ensure it's visible when sticky */
  z-index: 10; /* Keeps it above other content */
  padding: 8px 0; /* Adds spacing */
  /* background-color: rgba(255, 227, 203,1); */
  background-color: white;
}

.dark-mode .makeHeaderSticky {
  position: sticky;
  top: 0;
  background: white; /* Ensure it's visible when sticky */
  z-index: 10; /* Keeps it above other content */
  padding: 8px 0; /* Adds spacing */
  background-color: #3f4044;
}

.padd-rlb-5p {
  padding: 0 5px 5px 5px !important;
}

.dark-mode .padd-rlb-5p {
  border: none !important;
}