.statusBox p.item-val {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: grab;
    font-size: 12px;
}
.statusBox p span svg {
    font-size: 13px;
    margin-left: 10px;
    color: #2196f3;
}
.statusBox {
    padding: 10px;
    height: 100%;
}
.statusBox p.item-val:hover {
    background: #ddd2;
}

.statusBox p.item-val.sortable-chosen {
    background: #ddd2;
}
.statusBox > p {
    padding: 0 10px;
}
.statusBox p span.rank {
    color: #2196F3;
    display: inline-block;
    min-width: 50px;
    text-align: center;
}
.statusBox p {
    display: flex;
    justify-content: space-between;
}
.dark-mode .statusBox.white-bg {
    background: #5557;
}
.dark-mode p span.rank{
    color: #6ec9f2;
}