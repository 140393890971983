.pb-100vh{
    /* padding-bottom: 100vh !important; */
    padding-bottom: 30px !important;
}
.mbl-page-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.bottom-drawer-body {
    max-height: 70vh;
    overflow-y: scroll;
}
.mobile-formWrapper {
    padding-bottom: 80px;
}
.mobile-add-new-button{
    color: var(--orange);
    text-transform: none;
}
.mobile-add-new-button span {
    font-size: .8em;
}
.mobile-add-new-button svg {
    font-size: 1.3em;
    margin-right: 5px;
}
.mobile-navBar nav{
    background: var(--primary);
}
.mobile-card .first_row button {
    padding: 0;
}
.mobile-menus {
    display: flex;
    align-items: center;
}
.mobile-right-menus button svg {
    height: 20px;
    width: auto;
}
.mobile-card{
    background: #fff;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 10px;
}
.mobile-card .first_row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}
.mobile-card .first_row p a {
    color: var(--primary);
    text-decoration: none;
}
.mobile-card hr {
    margin: 10px 0;
}
.mobile-card .body p {
    font-size: .8em;
}
.mCardFooter span {
    font-size: 11px;
}
.mCardFooter {
    display: flex;
    justify-content: space-between;
}
.mCardFooter span.mcard-badges {
    padding: 3px 5px;
    background: #ddd5;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 5px;
}
.mCardFooter span.mcard-badges.contact{
    border-radius: 50px;
    min-width: 25px;
    min-height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #2a67a233;
    color: var(--primary);
}
.sla-holder{
    display: flex;
    align-items: center;
    gap: 10px;
}
.contactviewheader {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 17px;
}
.KeyValueLabel p {
    font-size: 17px;
    margin-bottom: 10px;
}
.KeyValueLabel span {
    font-size: 13px;
    color: #5558;
}
.searchBtn {
    border-radius: 8px;
    background: #fff;
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}
.searchBtn p{
    font-size: .9em;
    line-height: 1;
}
.no-min-width{
    min-width: auto !important;
}
.ticket-action-item {
    padding: 9px 17px;
    white-space: nowrap;
    font-size: .9em;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #ddd8;
}
.ticket-action-item svg {
    height: 21px;
    width: auto;
}
.add-new-ticket-btn {
    position: fixed;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    background: #EA6627;
    min-width: auto;
    padding: 19px;
    border-radius: 50px;
}
.add-new-ticket-btn:hover {
    background: #ca4f14;
}
.mobile-bottom-drawer div:has(.mobile-bottom-drawer-title) {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.mobile-bottom-drawer-title {
    font-weight: 600;
    font-size: 17px !important;
    padding-top: 15px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mobile-bottom-drawer-title span.rightLink {
    font-size: .7em;
    display: block;
    color: var(--primary);
}
.mobile-bottom-drawer p {
    font-size: 13px;
}
.mobile-bottom-drawer .sub-heading {
    font-size: 13px;
    color: var(--orange);
    font-weight: 600;
    display: block;
    padding-top: 15px;
}
.mobileRadioBox {
    display: block;
}
.mobileRadioBox-list label {
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid #ddd;
}
.mobileRadioBox-list label span {
    font-size: 13px;
    display: block;
}
.mobile-collapse-summary{
    background: transparent !important;
}
.mobileRadioBox-list label input:checked ~ span svg {
    fill: var(--orange);
}
.mobile-search-field .icon-holder {
    transform: translateX(-15px);
}
.mobile-search-field input{
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
.mobile-formWrapper-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px 22px;
    background: #fff;
    border-top: 1px solid #ddd4;
    z-index: 2;
}
.mobile-formWrapper-footer button {
    width: 100%;
    background: var(--orange) !important;
    color: #fff;
    padding: 10px;
    border: 1px solid;
    border-radius: 12px;
    text-transform: capitalize;
}
.mobile-formWrapper-footer button.btn-outlined {
    background: transparent !important;
    color: #000;
    border-color: #D0D5DD !important;
}
.mobile-formWrapper-footer > div {
    flex: 0 0 50%;
    padding: 10px 7px;
}
.mobile-search-field fieldset {
    border-radius: 8px;
}
.mobile-search-field {
    background: #fff;
    border-radius: 8px;
}
.mobile-subject-field fieldset {
    border: none !important;
    outline: none !important;
}
.mobile-subject-field input {
    padding: 10px 0 !important;
    line-height: 16px;
    font-size: 2.5em !important;
}
.mobile-subject-field input::placeholder, 
.mobile-subject-field input::-moz-placeholder,
.mobile-subject-field input::-webkit-input-placeholder {
    font-weight: 600;
}
.accordion-holder {
    margin-top: 15px;
}
.mobile-accrodion {
    border-radius: 12px !important;
    margin: 0 !important;
    box-shadow: 0 0 18px 0px #0002
}
.mobile-accrodion::before {
    background: #0000;
}
.collapse-title {
    font-weight: 500;
    font-size: .8rem;
}
.label-required b {
    color: red !important;
}
.collapse-body {
    padding: 0 16px 14px 16px;
}
.mobile-subject-field {
    margin-top: 15px;
}
.accordion-holder .app-field-label {
    font-size: .8em !important;
}
.accordion-holder .common-new-input.multiline > div{
    padding: 5px 0 !important;
}
.accordion-holder .common-new-input > div textarea {
    font-size: 1.6em !important;
}
.accordion-holder .common-new-input input, .accordion-holder .common-new-input fieldset {
    outline: none !important;
    border: none !important;
    padding-left: 5px !important;
    border-radius: 0 !important;
}
.mobile-dropdown {
    margin-bottom: 15px;
}
.mobile-dropdown:last-child {
    margin: 0;
}
.mobile-dropdown p {
    margin-bottom: 4px;
    font-size: .8em;
}
.mobile-dropdown .dropdown-ui p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9da7b7;
    font-size: .9em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 4px;
}
.mobile-dropdown .dropdown-ui p span.value {
    color: #000;
}
.drop-down-options li.active {
    background: #ddd5;
}
.mobile-field-note{
    color:var(--primary);
    font-size: .7em;
}
.mobile-dropdown .dropdown-ui p span.val {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.name-initial {
    font-size: 11px;
    background: #ECFDFF;
    color: #06AED4;
    line-height: 1;
    min-width: 24px;
    justify-content: center;
    height: 24px;
    font-weight: 500;
    align-items: center;
    border-radius: 50px;
    display: inline-flex;
    margin-right: 10px;
}
.drop-down-options li .name-initial.initial-1{
    background: #FEFBE8;
    color: #DC9D00;
}
.drop-down-options li .name-initial.initial-2{
    background: #f7f1ff;
    color: #9747ff;
}
.drop-down-options li .name-initial.initial-3{
    background: #ecfdff;
    color: #06aed4;
}
.drop-down-options li .name-initial.initial-4{
    background: #e6ffe8;
    color: #12b76a;
}
.drop-down-options li .name-initial.initial-5{
    background: #ffeae8;
    color: #b42318;
}
.mob-date-picker > div {
    width: 100%;
}
.mob-date-picker fieldset, .mob-date-picker input {
    border: none !important;
    outline: none;
}
.mob-date-picker input {
    padding-left: 0;
    font-size: .9em !important;
}
.mob-date-picker {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.mob-date-picker label {
    position: static;
    display: block;
    transform: none;
    font-size: .8em;
    color: #000 !important;
    line-height: 1;
}
.accordion-holder .common-new-input input {
    border-bottom: 1px solid #ddd !important;
}
.mob-effort-field {
    display: flex;
    align-items: end;
    gap: 16px;
}
.mob-effort-field > div {
    width: 100%;
}
.mobile-bottom-drawer-title span.rightLink{
    text-transform: uppercase;
}
.mbl-catrgory-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}
.mbl-catrgory-item button {
    min-width: auto;
}
.category-level2 {
    padding-left: 3px;
}
.category-level2 {
    padding-left: 6px;
}
.category-level2 .mbl-catrgory-item {
    background: #ddd4;
}
.category-level3 .mbl-catrgory-item {
    background: #5556;
}
.drop-down-options .items-holder {
    padding: 5px !important;
}
.mbl-catrgory-item svg {
    display: block;
}
.category-name svg {
    height: .8em;
    width: auto;
    color: var(--orange);
}
.category-level1 {
    box-shadow: 0 0 3px 0 #ddd;
    border-radius: 5px;
}
.category-name {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.category-level1 > .mbl-catrgory-item {
    border-bottom: 1px solid #ddd5;
}
.dropdown_option_span svg {
    font-size: 13px;
    color: #2196f3;
}
.tckt-tab-link {
    display: inline-flex;
    gap: 5px;
    font-size: 1em;
    align-items: center;
    text-transform: none;
    color: #667085;
    font-weight: 500 !important;
}
.tckt-tab-link svg{
    height: 1.4em;
    width: auto;
}
.tckt-tab-link svg path {
    stroke: #667085;
}
.tckt-tab-link svg.timeline-icon path{
    fill: #667085;
}
.ticket-tab-links > div > span {
    display: none;
}
.ticket-tab-links button {
    background: #F2F4F700;
    transition: .5s ease-in-out;
    border-radius: 8px;
    padding: 8px 16px;
    min-width: auto;
    min-height: auto;
}
.ticket-tab-links button.active{
    background: #F2F4F7;
}
.ticket-tab-links > div > div {
    gap: 10px;
}
.ticket-tab-links {
    min-height: auto;
    padding: 17px;
    background: #fff;
}
.ticket-banner h4 {
    color: var(--primary);
    font-weight: 800;
    font-size: 1.3em;
}
.ticket-banner {
    background: #fff;
    padding: 17px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}
.ticket-banner div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.ticket-banner .badge p {
    background: #f2f4f7;
    font-size: .8em;
    padding: 4px 12px;
    font-weight: 500;
    border-radius: 5px;
}
.ticket-tab-links button.active path {
    stroke: var(--primary);
}
.ticket-tab-links button.active span {
    color: var(--primary);
}
.ticket-tab-links button.active svg.timeline-icon path{
    fill : var(--primary);
}
.label-item label {
    font-size: .8em;
}
.label-item {
    padding-bottom: 10px;
}
.timeline-Row .timeSpan p {
    font-size: 9px;
    text-align: right;
    color: #5558;
    line-height: 1;
    margin: 0;
    margin-bottom: 10px;
    white-space: nowrap;
}
.timeline-Row .timeSpan p.date{
    color: #000;
}
.mobileFilterHolder {
    display: flex;
    /* padding-bottom: 100vh; */
}
.mobileFilterHolder .navs{
    display: flex;
    flex-direction: column;
    background: #FCFCFD;
}
.mobileFilterHolder .navs button {
    white-space: nowrap;
    font-size: 12px;
    color: #000;
    text-transform: unset;
    font-weight: 400 !important;
    font-size: .7rem;
    padding-left: 15px;
    padding-right: 15px;
}
.mobileFilterHolder .navs button.active {
    background: #F2F4F7;
    color: var(--orange) !important;
}
.filter-drawer .bottom-drawer-body{
    padding: 0 !important;
}
.mobileFilterHolder .filterItems {
    padding: 10px;
    width: 100%;
    height: 375px;
    overflow-y: scroll;
}
.mobile-filter-list > label {
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    margin: 0;
}
.mobile-filter-list > label span {
    font-size: .7rem;
}
.mobile-group-filter-item .mobile-filter-list {
    flex-direction: row;
    gap: 5px;
}
.mobile-group-filter-item .mobile-filter-list > label {
    border: 1px solid #ddd;
    border-radius: 5px;
    position: relative;
}
.mobile-group-filter-item .mobile-filter-list > label > span:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
}
.mobile-group-filter-item .mobile-filter-list > label > span:first-child svg{
    background: var(--orange);
    border-radius: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    color: #0000;
    border-radius: 5px;
    transition: .5s ease-in-out;
}
.mobile-group-filter-item .mobile-filter-list > label > span:first-child input:checked + svg{
    opacity: 0.2;
}
.mobile-group-filter-item .mobile-filter-list > label > span:last-child{
    padding: 3px 10px;
}
.mobile-group-filter .mobile-group-filter-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.mobile-group-filter .mobile-group-filter-item > .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.mobile-group-filter .mobile-group-filter-item > .title p {
    font-weight: 600;
    font-size: 11px;
}
.mobile-group-filter .mobile-group-filter-item > .title p.count {
    font-weight: 600;
    background: #fff;   
}
.mobile-filter-list > label span span.agentCount {
    background: #f0f3fe;
    display: inline-block;
    width: 17px;
    line-height: 17px;
    border-radius: 50%;
    text-align: center;
    margin-left: 5px;
}
.label-item p {
    word-break: break-word;
}
.mbl-quick-filter {
    background: #fff;
    padding: 15px 0;
}
.mbl-quick-filter button {
    font-size: 11px;
    color: #5559;
}
.mbl-quick-filter button.Mui-selected {
    color: #000;
}
.mbl-quick-filter .item-list .item {
    padding: 10px;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 10px;
    line-height: 1;
}
.mbl-quick-filter .item-list .item.active {
    background: #f1f1f1;
}
.mbl-quick-filter .item-list .item.active {
    color: #000 !important;
    font-weight: 500 !important;
}
.item-list {
    display: flex;
    gap: 10px;
    padding: 10px;
    overflow: hidden;
    overflow-x: scroll;
}
.mbl-quick-filter-row > div > span {
    background: var(--orange);
}
.accordion-holder.asset-accrodian > div {
    background: #fff;
    box-shadow: none;
    border: 1px solid #ddd;
}
.accordion-holder.asset-accrodian .mobile-collapse-summary {
    flex-direction: row-reverse;
    gap: 10px;
}
.asset-collapse-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.asset-collapse-title > span {
    padding: 0;
}
.mbl-snd-email-checkbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mbl-snd-email-checkbox > p {
    font-size: .8em;
}
.mbl-snd-email-checkbox > div > span {
    padding: 0;
    color: var(--orange) !important;
}
.mobileAttachment {
    cursor: pointer;
    border-radius: 0;
}
.mblAttachment {
    cursor: pointer;
}
.mobileAttachment img {
    height: 300px;
    object-fit: contain;
}
.mobileFileIcon {
    height: 50px;
    width: auto;
    background: #b8b8b8;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    max-width: 100%;
}
.mblViewFile .mobileFileIcon{
    height: 100px;
}
.mbl-attachIcon {
    position: relative;
    display: inline-block;
}

.mbl-attachIcon span {
    position: absolute;
    background: var(--orange);
    min-width: 24px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    top: -8px;
    right: 14px;
}
.mblViewFile{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: 100%;
    width: 100%;
    background: #0007;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.mblViewFile > div{
    border-radius: 10px;
}
.mblAssetItem {
    display: flex;
    gap: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px;
}
.mblAssetItem .name p {
    font-size: 11px;
}
.mblAssetItem .name p.tag {
    opacity: .5;
    font-size: 9px;
}
.mblAssetItem .name {
    width: 100%;
}
.mblAssetItem .icon svg {
    font-size: 22px;
    transform: translateY(4px);
}
.sendMailDrawer fieldset {
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
}
.sendMailDrawer .common-new-input-holder {
    margin-bottom: 10px;
}
@media screen and (max-width:680px) {
    .timeline-update > div {
        display: none;
    }
    .dark-mode .common-new-input>div{
        border : none;
    }
    .sm-d-none{
        display: none !important;
    }
    .timeline-Row .timeSpan {
        -webkit-flex: unset !important;
        -ms-flex: unset !important;
        flex: unset !important;
    }
    .timelineContent p {
        font-size: 12px;
        margin: 0;
        margin-bottom: 8px;
    }
    .timeline-update ul {
        padding: 0;
        margin: 0;
    }
    
}
.dark-mode .mbl-quick-filter {
    background: #1e1c2a;
}
.dark-mode .mbl-quick-filter button ,
.dark-mode .mobile-card .first_row p a ,
.dark-mode .mobile-formWrapper-footer button.btn-outlined,
.dark-mode .mob-date-picker label,
.dark-mode .ticket-banner h4 {
    color: #fff !important;
}
.dark-mode .searchBtn,
.dark-mode .mobile-card,
.dark-mode .mobile-navBar nav,
.dark-mode .mobile-formWrapper-footer,
.dark-mode .mbl-quick-filter .item-list .item.active ,
.dark-mode .mobile-search-field,
.dark-mode .accordion-holder.asset-accrodian>div,
.dark-mode .ticket-banner,
.dark-mode .ticket-tab-links,
.dark-mode .mobileFilterHolder .navs button,
.dark-mode .mobile-filter-list>label span span.agentCount,
.dark-mode .mobile-group-filter .mobile-group-filter-item>.title p.count{
    background: #2d2c2c;
    color: #fff !important;
}
.dark-mode .mblAttachment * {
    color: #2d2c2c;
}
.dark-mode .accordion-holder.asset-accrodian>div svg * {
    stroke: #fff;
}
.dark-mode  svg.noDataImg {
    filter: invert(.8);
}
.dark-mode .ticket-banner .badge p {
    color: #000 !important;
}
.dark-mode .ticket-action-item svg * {
    stroke: #fff;
}
.dark-mode .mobileFilterHolder .navs {
    background: #353535;
}
.dark-mode .mobileFilterHolder .navs button.active {
    background: #555;
    color: #fff;
}
.dark-mode .mobile-card .first_row button svg path {
    stroke: #fff !important;
}
.dark-mode .mobile-dropdown .dropdown-ui p span.val {
    color: #fff;
}
.dark-mode .drop-down-options li.active {
    color: var(--orange) !important;
}
.dark-mode .hover-notification-item {
    background: #555 !important;
    color: #fff;
}
.dark-mode .hover-notification-item * {
    color: #fff !important;
}