@keyframes fadeInUp {
    from{
        opacity: 0;
        transform: translateY(30px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}
.bot-holder {
    background: #fff;
    position: fixed;
    right: 30px;
    top: 75px;
    box-shadow: 0 0 3px 1px #5552;
    border-radius: 6px;
    z-index: 99999999;
    width: 350px;
    overflow: hidden;
    animation: .5s fadeInUp linear;
}
.bot-header .close{
    cursor: pointer;
}
.bot-header {
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    background: #bc59d436;
    justify-content: space-between;
}
.bot-avatar{
    border-radius: 0;
}
.chatItem .bot-name p,
.chatItem > p {
    font-size: 12px;
    color: #555;
    font-weight: 600;
}
.chatItem .bot-name {
    display: flex;
    align-items: end;
    gap: 5px;
}
.chatItem .message {
    display: flex;
    align-items: end;
}
.chatItem .message p {
    padding: 10px 15px;
    background: #ffe1b6;
    font-size: 13px;
    border-radius: 15px;
    border-top-left-radius: 5px;
}
.chatItem .message >span svg {
    font-size: 12px;
    color: #008544;
}
.chatItem.by-user {
    text-align: right;
    margin-bottom: 15px;
}
.chatItem.by-user .message{
    text-align: right;
    justify-content: end;
}
.chatItem.by-user .message p {
    border-radius: 15px;
    border-top-right-radius: 5px;
    background: #c2def9;
}
.bot-button {
    /* position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 999; */
}
.bot-button button {
    /* background: #ffffff;
    box-shadow: 0 0 3px 1px #ddd9;
    border-radius: 19px;
    cursor: pointer; */
}
.topbar-controls {
    display: flex;
    align-items: center;
}
.bot-footer input {
    padding: 15px !important;
}
.bot-footer fieldset {
    border: none !important;
}
.bot-footer {
    border-top: 1px solid #ddd;
    padding-right: 13px;
}
.bot-button .count {
    position: absolute;
    top: 0;
    right: 0;
    background: #ea6627;
    line-height: 21px;
    min-width: 21px;
    font-size: 11px;
    color: #fff;
    border-radius: 50px;
    transform: translate(23%, -50%);
}
.bot-body{
    padding: 15px;
}
.chatbody {
    height: 300px;
    overflow-y: scroll;
}
.chat-history-holder{
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #ddd;
}
.chat-history-body{
    height: 70vh;
}
.noscrollbar::-webkit-scrollbar {
    width: 5px;
}
/* .bot-button button .close {
    font-size: 50px;
} */
.dot-loader {
    width: 33px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#fff0);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
.dark-mode .topbar-controls {
    color: #fff;
}