@font-face {
  font-family: sf-pro;
  src: url(./assest/font/FontsFree-Net-SFProDisplay-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
/* iframe{
  pointer-events: none;
}

iframe{
  pointer-events: none;
} */

a.modebar-btn[data-title="Produced with Plotly.js (v2.26.0)"] {
  display: none;
}